import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase';
import axios from 'axios';
import { toast } from 'react-toastify';
import { addDays } from 'date-fns';

const ContextState = createContext()

const ContextAPI = ({ children }) => {
    //sidebar
    const [activeMenu, setActiveMenu] = useState(true)
    const [screenSize, setScreenSize] = useState(undefined)

    //Toasts
    const loginSuccessToast = () => toast.success('Logged In Successfully')
    const loginFailedToast = () => toast.warning('Email Or Password Incorrect')
    const logoutToast = () => toast.success('Logged Out Successfully')

    //AUTHENTICATION
    const [user, setUser] = useState(null)

    //Generating Home Page Stats
    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(user)
                localStorage.setItem('user', user?.email)
                //load specific pages first render
                if (user?.email === process.env.REACT_APP_CLIENT1) {
                    getTriangleAll('last_week_mon_sun')
                    setTriangleDaysButton('last_week_mon_sun')
                    getMorganHillAll('last_week_mon_sun')
                    setMorganHillDaysButton('last_week_mon_sun')
                    getCarolinaAll('last_week_mon_sun')
                    setCarolinaDaysButton('last_week_mon_sun')
                    getFeltonAll('last_week_mon_sun')
                    setFeltonDaysButton('last_week_mon_sun')
                    getCullmanAll('last_week_mon_sun')
                    setCullmanDaysButton('last_week_mon_sun')
                    getSkinAll('last_week_mon_sun')
                    setSkinDaysButton('last_week_mon_sun')
                    getSmileAll('last_week_mon_sun')
                    setSmileDaysButton('last_week_mon_sun')
                    getRiverRunAll('last_week_mon_sun')
                    setRiverRunDaysButton('last_week_mon_sun')
                    getPleasantAll('last_week_mon_sun')
                    setPleasantDaysButton('last_week_mon_sun')
                    getGarrisonvilleAll('last_week_mon_sun')
                    setGarrisonvilleDaysButton('last_week_mon_sun')
                    getCascadesAll('last_week_mon_sun')
                    setCascadesDaysButton('last_week_mon_sun')
                    getTownsAll('last_week_mon_sun')
                    setTownsDaysButton('last_week_mon_sun')
                    getGastoniaAll('last_week_mon_sun')
                    setGastoniaDaysButton('last_week_mon_sun')

                    ///last 7 days
                    getTriangleAll7('last_7d')
                    getMorganHillAll7('last_7d')
                    getCarolinaAll7('last_7d')
                    getFeltonAll7('last_7d')
                    getCullmanAll7('last_7d')
                    getSkinAll7('last_7d')
                    getSmileAll7('last_7d')
                    getRiverRunAll7('last_7d')
                    getPleasantAll7('last_7d')
                    getGarrisonvilleAll7('last_7d')
                    getCascadesAll7('last_7d')
                    getTownsAll7('last_7d')
                    getGastoniaAll7('last_7d')
                } else if (user?.email === process.env.REACT_APP_CLIENT2) {
                    getTriangleAll('last_week_mon_sun')
                    setTriangleDaysButton('last_week_mon_sun')
                    getFeltonAll('last_week_mon_sun')
                    setFeltonDaysButton('last_week_mon_sun')
                } else if (user?.email === process.env.REACT_APP_CLIENT3) {
                    getMorganHillAll('last_week_mon_sun')
                    setMorganHillDaysButton('last_week_mon_sun')
                } else if (user?.email === process.env.REACT_APP_CLIENT4) {
                    getCarolinaAll('last_week_mon_sun')
                    setCarolinaDaysButton('last_week_mon_sun')
                }
            } else {
                setUser(null)
            }
        })
    }, [])
    ///


    //general fb
    const getFB = async (clientID, preset) => {
        return await axios.get('https://oradigital.herokuapp.com/fb', { params: { client: clientID, datePreset: preset, level: 'campaign' } })
            .then((response) => {
                const data = response?.data?.data
                for (var i = 0; i < data?.length; i++) {
                    if (data?.length > 0) {
                        data[i]["clientID"] = clientID
                    }
                }
                return data
            })
            .catch((error) => console.log(error))
    }

    //general fb custom
    const getFBCustom = async (clientID, startDate, endDate) => {
        return await axios.get('https://oradigital.herokuapp.com/fbcustom', { params: { client: clientID, startDate: startDate, endDate: endDate, level: 'campaign' } })
            .then((response) => {
                const data = response?.data?.data
                for (var i = 0; i < data?.length; i++) {
                    if (data?.length > 0) {
                        data[i]["clientID"] = clientID
                    }
                }
                return data
            })
            .catch((error) => console.log(error))
    }


    //this is general liine
    const getLiine = async (id, location, start, end) => {
        const data = await axios.get('https://oradigital.herokuapp.com/liine', { params: { id: id, location: location, start: start, end: end } })
            .then((res) => {
                //console.log(res.data)
                //setLiineData(res.data)
                return res.data
            })
            .catch((error) => console.log(error))
        return data
    }

    //general ghl
    /*     const getGHLGeneral = async (location_id) => {
            const url = 'http://localhost:5000/getGHLContacts'
            const data = await axios.get(url, { params: { location: location_id } })
                .then((response) => {
                    //console.log(response.data)
                    //setFeltonAllGHLData(response.data)
                    return response.data
                })
                .catch((error) => {
                    console.log(error)
                })
            return data
        } */

    //ghl general for multiple locations
    const getGHL = async (location_id) => {
        const url = 'https://oradigital.herokuapp.com/getghlcontacts'
        const data = await axios.get(url, { params: { location: location_id } })
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                console.log(error)
            })
        return data
    }


    ///TRIANGLE DENTISTRY///
    const [triangleFBStartEnd, setTriangleFBStartEnd] = useState({
        startDate: null,
        endDate: null,
    })

    const triangleAllGHLAccounts = [
        process.env.REACT_APP_TRIANGLE_GHL_ID_BRIERCREEK,
        process.env.REACT_APP_TRIANGLE_GHL_ID_FUQUAYVARINA,
        process.env.REACT_APP_TRIANGLE_GHL_ID_GREENLEVEL,
        process.env.REACT_APP_TRIANGLE_GHL_ID_KELLYROAD,
        process.env.REACT_APP_TRIANGLE_GHL_ID_MORRISVILLE,
        process.env.REACT_APP_TRIANGLE_GHL_ID_WAKEFOREST,
    ]

    const triangleAllLocations = [
        ""
    ]

    const getGHLAllTriangle = async () => {
        //setTriangleAllGHLData([])

        Promise?.all(triangleAllGHLAccounts?.map(async (location) => {
            const data = await getGHL(location)
            setTriangleAllGHLData((prevState) => [...prevState, { [location]: data }])
        }))
    }


    // Overview Triangle
    const triangleAllAccounts = [
        { fb: process.env.REACT_APP_TRIANGLE_FB1, ghl: '' },
        { fb: process.env.REACT_APP_TRIANGLE_FB2, ghl: '' },
        { fb: process.env.REACT_APP_TRIANGLE_FB3, ghl: '' },
        { fb: process.env.REACT_APP_TRIANGLE_FB4, ghl: '' },
        { fb: process.env.REACT_APP_TRIANGLE_FB5, ghl: '' },
        { fb: process.env.REACT_APP_TRIANGLE_FB6, ghl: '' },
    ]

    const [triangleAllFBData, setTriangleAllFBData] = useState([])
    const [triangleAllGHLData, setTriangleAllGHLData] = useState([])
    const [triangleRun, setTriangleRun] = useState(true)
    const [triangleFBCustomDates, setTriangleFBCustomDates] = useState([{
        startDate: addDays(new Date(), -1),
        endDate: addDays(new Date(), 0),
        key: 'selection'
    }])



    const getTriangleAll = async (preset) => {
        setTriangleAllFBData([])

        Promise?.all(triangleAllAccounts?.map(async (client) => {
            const data = await getFB(client.fb, preset)
            setTriangleAllFBData((prevState) => [...prevState, data])
            setTriangleFBStartEnd({
                startDate: new Date((data[0]?.date_start)?.replace(/-/g, '\/')),
                endDate: new Date((data[0]?.date_stop)?.replace(/-/g, '\/'))
            })
        })).then(() => setTriangleRun(prevState => !prevState))
    }

    const getTriangleAllCustom = async () => {
        //get fb stats
        setTriangleAllFBData([])

        Promise?.all(triangleAllAccounts?.map(async (client) => {
            const data = await getFBCustom(client.fb, triangleFBCustomDates[0]?.startDate.toISOString().split('T')[0], triangleFBCustomDates[0]?.endDate.toISOString().split('T')[0])
            setTriangleAllFBData((prevState) => [...prevState, data])
            setTriangleFBStartEnd({
                startDate: new Date((data[0]?.date_start)?.replace(/-/g, '\/')),
                endDate: new Date((data[0]?.date_stop)?.replace(/-/g, '\/'))
            })
        })).then(() => setTriangleRun(prevState => !prevState))
    }

    //now uses Liine
    /* Liine */
    const [allLiineDataTriangle, setAllLiineDataTriangle] = useState([])

    const getTriangleLiineAll = async () => {
        let start = + triangleFBStartEnd?.startDate?.getFullYear() + "-" + ("0" + (triangleFBStartEnd?.startDate?.getMonth() + 1)).slice(-2) + "-" + ("0" + triangleFBStartEnd?.startDate?.getDate()).slice(-2)
        let end = triangleFBStartEnd?.endDate?.getFullYear() + "-" + ("0" + (triangleFBStartEnd?.endDate?.getMonth() + 1)).slice(-2) + "-" + ("0" + triangleFBStartEnd?.endDate?.getDate()).slice(-2)
        let list = []

        //this is running it 13 times because location no longer works
        await Promise.all(triangleAllLocations?.map(async (e) => {
            const data = await getLiine(513, e, start, end)
            list.push(data?.interactions)
        })).then(() => {
            setAllLiineDataTriangle(list)
        })
        //run only 1 time
    }
    /* Liine */


    const [triangleDaysButton, setTriangleDaysButton] = useState('')
    const triangleMounted = useRef(false)

    useEffect(() => {
        if (triangleMounted.current) {
            //getGHLAllTriangle()
            getTriangleLiineAll()
        } else {
            triangleMounted.current = true
        }
    }, [triangleRun])
    ///TRIANGLE DENTISTRY///



    ////MORGAN HILL///
    // Overview
    const morganHillAllAccounts = [
        { fb: process.env.REACT_APP_MORGANHILL_FB, ghl: process.env.REACT_APP_MORGANHILL_GHL }
    ]

    const [morganHillAllFBData, setMorganHillAllFBData] = useState([])
    const [morganHillAllGHLData, setMorganHillAllGHLData] = useState([])
    const [morganHillRun, setMorganHillRun] = useState(true)
    const [morganHillFBCustomDates, setMorganHillFBCustomDates] = useState([{
        startDate: addDays(new Date(), -1),
        endDate: addDays(new Date(), 0),
        key: 'selection'
    }])

    const getMorganHillAll = async (preset) => {
        setMorganHillAllFBData([])

        Promise?.all(morganHillAllAccounts?.map(async (client) => {
            const data = await getFB(client.fb, preset)
            setMorganHillAllFBData((prevState) => [...prevState, data])
        })).then(() => setMorganHillRun(prevState => !prevState))
    }

    const getMorganHillAllCustom = async () => {
        setMorganHillAllFBData([])

        Promise?.all(morganHillAllAccounts?.map(async (client) => {
            const data = await getFBCustom(client.fb, morganHillFBCustomDates[0]?.startDate.toISOString().split('T')[0], morganHillFBCustomDates[0]?.endDate.toISOString().split('T')[0])
            setMorganHillAllFBData((prevState) => [...prevState, data])
        })).then(() => setMorganHillRun(prevState => !prevState))
    }
    //FB CUSTOM DATES

    const getGHLMorganHill = async (location_id) => {
        const url = 'https://oradigital.herokuapp.com/getghlcontacts'
        await axios.get(url, { params: { location: location_id } })
            .then((response) => {
                setMorganHillAllGHLData(response.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const [morganHillDaysButton, setMorganHillDaysButton] = useState('')
    const morganHillMounted = useRef(false)

    useEffect(() => {
        if (morganHillMounted.current) {
            getGHLMorganHill(process.env.REACT_APP_MORGANHILL_GHL_ID)
        } else {
            morganHillMounted.current = true
        }
    }, [morganHillRun])
    //////MORGAN HILL//////


    ///CAROLINA LIINE///
    const [carolinaFBStartEnd, setCarolinaFBStartEnd] = useState({
        startDate: null,
        endDate: null,
    })

    /* Overview Carolina*/
    const carolinaAllAccounts = [
        { fb: process.env.REACT_APP_CAROLINA_FB0, ghl: '' },
        { fb: process.env.REACT_APP_CAROLINA_FB1, ghl: '' },
        { fb: process.env.REACT_APP_CAROLINA_FB2, ghl: '' },
        { fb: process.env.REACT_APP_CAROLINA_FB3, ghl: '' },
        { fb: process.env.REACT_APP_CAROLINA_FB4, ghl: '' },
        { fb: process.env.REACT_APP_CAROLINA_FB5, ghl: '' },
        { fb: process.env.REACT_APP_CAROLINA_FB6, ghl: '' },
        { fb: process.env.REACT_APP_CAROLINA_FB7, ghl: '' },
        { fb: process.env.REACT_APP_CAROLINA_FB8, ghl: '' },
        { fb: process.env.REACT_APP_CAROLINA_FB9, ghl: '' },
        { fb: process.env.REACT_APP_CAROLINA_FB10, ghl: '' },
        { fb: process.env.REACT_APP_CAROLINA_FB11, ghl: '' },
        { fb: process.env.REACT_APP_CAROLINA_FB12, ghl: '' },
        { fb: process.env.REACT_APP_CAROLINA_FB13, ghl: '' },
        { fb: process.env.REACT_APP_CAROLINA_FB14, ghl: '' },
    ]

    const carolinaAllLocations = [
        ""
        /* "Asheville, NC",
        "Cary, NC",
        "Chapel Hill, NC",
        "Fayetteville, NC",
        "Fuquay Varina, NC",
        "Garner, NC",
        "Midtown",
        "Porters Neck, NC",
        "Raeford, NC",
        "Southern Pines, NC",
        "Spring Lake, NC",
        "The Pointe",
        "Winston-Salem, NC" */
    ]

    const [carolinaAllFBData, setCarolinaAllFBData] = useState([])
    const [carolinaRun, setCarolinaRun] = useState(true)

    const getCarolinaAll = async (preset) => {
        //get fb stats
        setCarolinaAllFBData([])

        Promise?.all(carolinaAllAccounts?.map(async (client) => {
            const data = await getFB(client.fb, preset)
            setCarolinaAllFBData((prevState) => [...prevState, data])
            //setting date here so liine api call works
            setCarolinaFBStartEnd({
                startDate: new Date((data[0]?.date_start)?.replace(/-/g, '\/')),
                endDate: new Date((data[0]?.date_stop)?.replace(/-/g, '\/'))
            })
        })).then(() => setCarolinaRun(prevState => !prevState))

    }

    //FB CUSTOM DATES
    const getCarolinaAllCustom = async () => {
        //get fb stats
        setCarolinaAllFBData([])

        Promise?.all(carolinaAllAccounts?.map(async (client) => {
            const data = await getFBCustom(client.fb, carolinaFBCustomDates[0]?.startDate.toISOString().split('T')[0], carolinaFBCustomDates[0]?.endDate.toISOString().split('T')[0])
            setCarolinaAllFBData((prevState) => [...prevState, data])
            setCarolinaFBStartEnd({
                startDate: new Date((data[0]?.date_start)?.replace(/-/g, '\/')),
                endDate: new Date((data[0]?.date_stop)?.replace(/-/g, '\/'))
            })
        })).then(() => setCarolinaRun(prevState => !prevState))
    }
    //FB CUSTOM DATES
    const [carolinaFBCustomDates, setCarolinaFBCustomDates] = useState([{
        startDate: addDays(new Date(), -1),
        endDate: addDays(new Date(), 0),
        key: 'selection'
    }])

    const [carolinaDaysButton, setCarolinaDaysButton] = useState('')
    const carolinaMounted = useRef(false)

    /* Liine */
    const [allLiineData, setAllLiineData] = useState([])

    const getCarolinaLiineAll = async () => {
        let start = + carolinaFBStartEnd?.startDate?.getFullYear() + "-" + ("0" + (carolinaFBStartEnd?.startDate?.getMonth() + 1)).slice(-2) + "-" + ("0" + carolinaFBStartEnd?.startDate?.getDate()).slice(-2)
        let end = carolinaFBStartEnd?.endDate?.getFullYear() + "-" + ("0" + (carolinaFBStartEnd?.endDate?.getMonth() + 1)).slice(-2) + "-" + ("0" + carolinaFBStartEnd?.endDate?.getDate()).slice(-2)
        let list = []
        //await getLiine(257, "Chapel Hill, NC", start, end)
        //console.log(start, end)

        //this is running it 13 times because location no longer works
        await Promise.all(carolinaAllLocations?.map(async (e) => {
            const data = await getLiine(257, e, start, end)
            list.push(data?.interactions)
        })).then(() => {
            setAllLiineData(list)
        })
        //run only 1 time
    }
    /* Liine */

    useEffect(() => {
        if (carolinaMounted.current) {
            getCarolinaLiineAll()
        } else {
            carolinaMounted.current = true
        }
    }, [carolinaRun])
    ///CAROLINA///


    ///FELTON GHL///
    const feltonAllAccounts = [
        { fb: process.env.REACT_APP_FELTON_FB, ghl: '' }
    ]
    /*   const feltonAllLocations = ["Felton Dentistry"] */
    const [feltonAllFBData, setFeltonAllFBData] = useState([])

    const getFeltonAll = async (preset) => {
        //get fb stats
        setFeltonAllFBData([])

        Promise?.all(feltonAllAccounts?.map(async (client) => {
            const data = await getFB(client.fb, preset)
            setFeltonAllFBData((prevState) => [...prevState, data])
        })).then(() => setFeltonRun(prevState => !prevState))

    }

    //FB CUSTOM DATES
    const [feltonFBCustomDates, setFeltonFBCustomDates] = useState([{
        startDate: addDays(new Date(), -1),
        endDate: addDays(new Date(), 0),
        key: 'selection'
    }])

    const getFeltonCustom = async () => {
        setFeltonAllFBData([])

        Promise?.all(feltonAllAccounts?.map(async (client) => {
            const data = await getFBCustom(client.fb, feltonFBCustomDates[0]?.startDate.toISOString().split('T')[0], feltonFBCustomDates[0]?.endDate.toISOString().split('T')[0])
            setFeltonAllFBData((prevState) => [...prevState, data])
        })).then(() => setFeltonRun(prevState => !prevState))
    }
    //FB CUSTOM DATES
    const [feltonDaysButton, setFeltonDaysButton] = useState('')
    const [feltonAllGHLData, setFeltonAllGHLData] = useState([])

    const [feltonRun, setFeltonRun] = useState(true)
    const feltonMounted = useRef(false)

    const getGHLFelton = async (location_id) => {
        const url = 'https://oradigital.herokuapp.com/getghlcontacts'
        await axios.get(url, { params: { location: location_id } })
            .then((response) => {
                setFeltonAllGHLData(response.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        if (feltonMounted.current) {
            getGHLFelton(process.env.REACT_APP_FELTON_GHL_ID)
        } else {
            feltonMounted.current = true
        }
    }, [feltonRun])
    ///FELTON///



    ///CULLMAN  LIINE///
    const [cullmanFBStartEnd, setCullmanFBStartEnd] = useState({
        startDate: null,
        endDate: null,
    })

    const cullmanAllAccounts = [
        { fb: process.env.REACT_APP_CULLMAN_FB }
    ]

    const cullmanAllLocations = [
        ""
    ]

    const [cullmanAllFBData, setCullmanAllFBData] = useState([])
    const [cullmanRun, setCullmanRun] = useState(true)

    const getCullmanAll = async (preset) => {
        //get fb stats
        setCullmanAllFBData([])

        Promise?.all(cullmanAllAccounts?.map(async (client) => {
            const data = await getFB(client.fb, preset)
            setCullmanAllFBData((prevState) => [...prevState, data])
            setCullmanFBStartEnd({
                startDate: new Date((data[0]?.date_start)?.replace(/-/g, '\/')),
                endDate: new Date((data[0]?.date_stop)?.replace(/-/g, '\/'))
            })
        })).then(() => setCullmanRun(prevState => !prevState))

    }

    const getCullmanAllCustom = async () => {
        //get fb stats
        setCullmanAllFBData([])

        let startDate = cullmanFBCustomDates[0]?.startDate.toISOString().split('T')[0]
        let endDate = cullmanFBCustomDates[0]?.endDate.toISOString().split('T')[0]

        Promise?.all(cullmanAllAccounts?.map(async (client) => {
            const data = await getFBCustom(client.fb, startDate, endDate)
            setCullmanAllFBData((prevState) => [...prevState, data])
        })).then(() => setCullmanRun(prevState => !prevState))
    }

    //FB CUSTOM DATES
    const [cullmanFBCustomDates, setCullmanFBCustomDates] = useState([{
        startDate: addDays(new Date(), -1),
        endDate: addDays(new Date(), 0),
        key: 'selection'
    }])

    const [cullmanDaysButton, setCullmanDaysButton] = useState('')
    const cullmanMounted = useRef(false)

    useEffect(() => {
        if (cullmanMounted.current) {
            getCullmanLiineAll()
        } else {
            cullmanMounted.current = true
        }
    }, [cullmanRun])
    /* Overview */

    /* Liine */
    const [cullmanLiineData, setCullmanLiineData] = useState([])

    const getCullmanLiineAll = async () => {
        let start = + cullmanFBStartEnd?.startDate?.getFullYear() + "-" + ("0" + (cullmanFBStartEnd?.startDate?.getMonth() + 1)).slice(-2) + "-" + ("0" + cullmanFBStartEnd?.startDate?.getDate()).slice(-2)
        let end = cullmanFBStartEnd?.endDate?.getFullYear() + "-" + ("0" + (cullmanFBStartEnd?.endDate?.getMonth() + 1)).slice(-2) + "-" + ("0" + cullmanFBStartEnd?.endDate?.getDate()).slice(-2)
        let list = []

        //this is running it 13 times because location no longer works
        await Promise.all(cullmanAllLocations?.map(async (e) => {
            const data = await getLiine(355, e, start, end)
            list.push(data?.interactions)
        })).then(() => {
            setCullmanLiineData(list)
        })
        //run only 1 time
    }
    /* Liine */
    ///CULLMAN///


    ///SKIN ESSENTIALS Liine///
    const [skinFBStartEnd, setSkinFBStartEnd] = useState({
        startDate: null,
        endDate: null,
    })

    const skinAllAccounts = [
        { fb: process.env.REACT_APP_SKIN_FB }
    ]

    const skinAllLocations = [
        ""
    ]

    const [skinAllFBData, setSkinAllFBData] = useState([])
    const [skinRun, setSkinRun] = useState(true)

    const getSkinAll = async (preset) => {
        //get fb stats
        setSkinAllFBData([])

        Promise?.all(skinAllAccounts?.map(async (client) => {
            const data = await getFB(client.fb, preset)
            setSkinAllFBData((prevState) => [...prevState, data])
            setSkinFBStartEnd({
                startDate: new Date((data[0]?.date_start)?.replace(/-/g, '\/')),
                endDate: new Date((data[0]?.date_stop)?.replace(/-/g, '\/'))
            })
        })).then(() => setSkinRun(prevState => !prevState))

    }

    const getSkinAllCustom = async () => {
        //get fb stats
        setSkinAllFBData([])

        let startDate = skinFBCustomDates[0]?.startDate.toISOString().split('T')[0]
        let endDate = skinFBCustomDates[0]?.endDate.toISOString().split('T')[0]

        Promise?.all(skinAllAccounts?.map(async (client) => {
            const data = await getFBCustom(client.fb, startDate, endDate)
            setSkinAllFBData((prevState) => [...prevState, data])
        })).then(() => setSkinRun(prevState => !prevState))
    }

    //FB CUSTOM DATES
    const [skinFBCustomDates, setSkinFBCustomDates] = useState([{
        startDate: addDays(new Date(), -1),
        endDate: addDays(new Date(), 0),
        key: 'selection'
    }])

    const [skinDaysButton, setSkinDaysButton] = useState('')
    const skinMounted = useRef(false)

    useEffect(() => {
        if (skinMounted.current) {
            getSkinLiineAll()
        } else {
            skinMounted.current = true
        }
    }, [skinRun])
    /* Overview */

    /* Liine */
    const [skinLiineData, setSkinLiineData] = useState([])

    const getSkinLiineAll = async () => {
        let start = + skinFBStartEnd?.startDate?.getFullYear() + "-" + ("0" + (skinFBStartEnd?.startDate?.getMonth() + 1)).slice(-2) + "-" + ("0" + skinFBStartEnd?.startDate?.getDate()).slice(-2)
        let end = skinFBStartEnd?.endDate?.getFullYear() + "-" + ("0" + (skinFBStartEnd?.endDate?.getMonth() + 1)).slice(-2) + "-" + ("0" + skinFBStartEnd?.endDate?.getDate()).slice(-2)
        let list = []

        //this is running it 13 times because location no longer works
        await Promise.all(skinAllLocations?.map(async (e) => {
            const data = await getLiine(277, e, start, end)
            list.push(data?.interactions)
        })).then(() => {
            setSkinLiineData(list)
        })
        //run only 1 time
    }
    /* Liine */
    ///SKIN ESSENTIAL///


    ///SMILE  GHL///
    const smileAllAccounts = [
        { fb: process.env.REACT_APP_SMILE_FB }
    ]
    const smileAllLocations = ["Smile Studio"]

    const [smileAllFBData, setSmileAllFBData] = useState([])
    const [smileDaysButton, setSmileDaysButton] = useState('')
    const [smileAllGHLData, setSmileAllGHLData] = useState([])
    const [smileRun, setSmileRun] = useState(true)
    const smileMounted = useRef(false)

    const getSmileAll = async (preset) => {
        //get fb stats
        setSmileAllFBData([])

        Promise?.all(smileAllAccounts?.map(async (client) => {
            const data = await getFB(client.fb, preset)
            setSmileAllFBData((prevState) => [...prevState, data])
        })).then(() => setSmileRun(prevState => !prevState))
    }

    //FB CUSTOM DATES
    const [smileFBCustomDates, setSmileFBCustomDates] = useState([{
        startDate: addDays(new Date(), -1),
        endDate: addDays(new Date(), 0),
        key: 'selection'
    }])

    const getSmileCustom = async () => {
        //get fb stats
        setSmileAllFBData([])

        let startDate = smileFBCustomDates[0]?.startDate.toISOString().split('T')[0]
        let endDate = smileFBCustomDates[0]?.endDate.toISOString().split('T')[0]

        Promise?.all(smileAllAccounts?.map(async (client) => {
            const data = await getFBCustom(client.fb, startDate, endDate)
            setSmileAllFBData((prevState) => [...prevState, data])
        })).then(() => setSmileRun(prevState => !prevState))
    }
    //FB CUSTOM DATES

    const getGHLSmile = async (location_id) => {
        const url = 'https://oradigital.herokuapp.com/getghlcontacts'
        await axios.get(url, { params: { location: location_id } })
            .then((response) => {
                //console.log(response.data)
                setSmileAllGHLData(response.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        if (smileMounted.current) {
            getGHLSmile(process.env.REACT_APP_SMILE_GHL_ID)
        } else {
            smileMounted.current = true
        }
    }, [smileRun])
    ///SMILE///


    ///RIVER RUN - LIINE///
    const [riverRunFBStartEnd, setRiverRunFBStartEnd] = useState({
        startDate: null,
        endDate: null,
    })

    const riverRunAllAccounts = [
        { fb: process.env.REACT_APP_RIVERRUN }
    ]

    const riverRunAllLocations = [
        ""
    ]

    const [riverRunAllFBData, setRiverRunAllFBData] = useState([])
    const [riverRunRun, setRiverRunRun] = useState(true)

    const getRiverRunAll = async (preset) => {
        //get fb stats
        setRiverRunAllFBData([])

        Promise?.all(riverRunAllAccounts?.map(async (client) => {
            const data = await getFB(client.fb, preset)
            setRiverRunAllFBData((prevState) => [...prevState, data])
            setRiverRunFBStartEnd({
                startDate: new Date((data[0]?.date_start)?.replace(/-/g, '\/')),
                endDate: new Date((data[0]?.date_stop)?.replace(/-/g, '\/'))
            })
        })).then(() => setRiverRunRun(prevState => !prevState))

    }

    //FB CUSTOM DATES
    const [riverRunFBCustomDates, setRiverRunFBCustomDates] = useState([{
        startDate: addDays(new Date(), -1),
        endDate: addDays(new Date(), 0),
        key: 'selection'
    }])

    const getRiverRunAllCustom = async () => {
        //get fb stats
        setRiverRunAllFBData([])

        let startDate = riverRunFBCustomDates[0]?.startDate.toISOString().split('T')[0]
        let endDate = riverRunFBCustomDates[0]?.endDate.toISOString().split('T')[0]

        Promise?.all(riverRunAllAccounts?.map(async (client) => {
            const data = await getFBCustom(client.fb, startDate, endDate)
            setRiverRunAllFBData((prevState) => [...prevState, data])
        })).then(() => setRiverRunRun(prevState => !prevState))
    }

    const [riverRunDaysButton, setRiverRunDaysButton] = useState('')
    const riverRunMounted = useRef(false)

    useEffect(() => {
        if (riverRunMounted.current) {
            getRiverRunLiineAll()
        } else {
            riverRunMounted.current = true
        }
    }, [riverRunRun])
    /* Overview */

    /* Liine */
    const [riverRunLiineData, setRiverRunLiineData] = useState([])

    const getRiverRunLiineAll = async () => {
        let start = + riverRunFBStartEnd?.startDate?.getFullYear() + "-" + ("0" + (riverRunFBStartEnd?.startDate?.getMonth() + 1)).slice(-2) + "-" + ("0" + riverRunFBStartEnd?.startDate?.getDate()).slice(-2)
        let end = riverRunFBStartEnd?.endDate?.getFullYear() + "-" + ("0" + (riverRunFBStartEnd?.endDate?.getMonth() + 1)).slice(-2) + "-" + ("0" + riverRunFBStartEnd?.endDate?.getDate()).slice(-2)
        let list = []

        //this is running it 13 times because location no longer works
        await Promise.all(riverRunAllLocations?.map(async (e) => {
            const data = await getLiine(352, e, start, end)
            list.push(data?.interactions)
        })).then(() => {
            setRiverRunLiineData(list)
        })
        //run only 1 time
    }
    /* Liine */
    ///River Run///


    ///PLEASANT GHL///
    const pleasantAllAccounts = [
        { fb: process.env.REACT_APP_PLEASANT_FB }
    ]

    const [pleasantAllFBData, setPleasantAllFBData] = useState([])
    const [pleasantDaysButton, setPleasantDaysButton] = useState('')
    const [pleasantAllGHLData, setPleasantAllGHLData] = useState([])
    const [pleasantRun, setPleasantRun] = useState(true)
    const pleasantMounted = useRef(false)

    const getPleasantAll = async (preset) => {
        //get fb stats
        setPleasantAllFBData([])

        Promise?.all(pleasantAllAccounts?.map(async (client) => {
            const data = await getFB(client.fb, preset)
            setPleasantAllFBData((prevState) => [...prevState, data])
        })).then(() => setPleasantRun(prevState => !prevState))
    }

    //FB CUSTOM DATES
    const [pleasantFBCustomDates, setPleasantFBCustomDates] = useState([{
        startDate: addDays(new Date(), -1),
        endDate: addDays(new Date(), 0),
        key: 'selection'
    }])

    const getPleasantCustom = async () => {
        //get fb stats
        setPleasantAllFBData([])

        Promise?.all(pleasantAllAccounts?.map(async (client) => {
            const data = await getFBCustom(client.fb, pleasantFBCustomDates[0]?.startDate.toISOString().split('T')[0], pleasantFBCustomDates[0]?.endDate.toISOString().split('T')[0])
            setPleasantAllFBData((prevState) => [...prevState, data])
        })).then(() => setPleasantRun(prevState => !prevState))
    }

    //FB CUSTOM DATES
    const getGHLPleasant = async (location_id) => {
        const url = 'https://oradigital.herokuapp.com/getghlcontacts'
        await axios.get(url, { params: { location: location_id } })
            .then((response) => {
                //console.log(response.data)
                setPleasantAllGHLData(response.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        if (pleasantMounted.current) {
            //getGHLPleasant(process.env.REACT_APP_PLEASANT_GHL_ID)
        } else {
            pleasantMounted.current = true
        }
    }, [pleasantRun])
    ///PLEASANT///


    ///GARRISONVILLE GHL///
    const garrisonvilleAllAccounts = [
        { fb: process.env.REACT_APP_GARRISONVILLE_FB }
    ]

    const [garrisonvilleAllFBData, setGarrisonvilleAllFBData] = useState([])
    const [garrisonvilleDaysButton, setGarrisonvilleDaysButton] = useState('')
    const [garrisonvilleAllGHLData, setGarrisonvilleAllGHLData] = useState([])
    const [garrisonvilleRun, setGarrisonvilleRun] = useState(true)
    const garrisonvilleMounted = useRef(false)

    const getGarrisonvilleAll = async (preset) => {
        //get fb stats
        setGarrisonvilleAllFBData([])

        Promise?.all(garrisonvilleAllAccounts?.map(async (client) => {
            const data = await getFB(client.fb, preset)
            setGarrisonvilleAllFBData((prevState) => [...prevState, data])
        })).then(() => setGarrisonvilleRun(prevState => !prevState))
    }

    //FB CUSTOM DATES
    const [garrisonvilleFBCustomDates, setGarrisonvilleFBCustomDates] = useState([{
        startDate: addDays(new Date(), -1),
        endDate: addDays(new Date(), 0),
        key: 'selection'
    }])

    const getGarrisonvilleCustom = async () => {
        //get fb stats
        setGarrisonvilleAllFBData([])

        let startDate = garrisonvilleFBCustomDates[0]?.startDate.toISOString().split('T')[0]
        let endDate = garrisonvilleFBCustomDates[0]?.endDate.toISOString().split('T')[0]

        Promise?.all(garrisonvilleAllAccounts?.map(async (client) => {
            const data = await getFBCustom(client.fb, startDate, endDate)
            setGarrisonvilleAllFBData((prevState) => [...prevState, data])
        })).then(() => setGarrisonvilleRun(prevState => !prevState))
    }

    //FB CUSTOM DATES
    const getGHLGarrisonville = async (location_id) => {
        const url = 'https://oradigital.herokuapp.com/getghlcontacts'
        await axios.get(url, { params: { location: location_id } })
            .then((response) => {
                //console.log(response.data)
                setGarrisonvilleAllGHLData(response.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        if (garrisonvilleMounted.current) {
            //getGHLGarrisonville(process.env.REACT_APP_PLEASANT_GHL_ID)
        } else {
            garrisonvilleMounted.current = true
        }
    }, [garrisonvilleRun])
    ///Garrisonville///


    ///CASCADES LIINE///
    const [cascadesFBStartEnd, setCascadesFBStartEnd] = useState({
        startDate: null,
        endDate: null,
    })

    const cascadesAllAccounts = [
        { fb: process.env.REACT_APP_CASCADES_FB }
    ]

    const cascadesAllLocations = [
        ""
    ]

    const [cascadesAllFBData, setCascadesAllFBData] = useState([])
    const [cascadesRun, setCascadesRun] = useState(true)

    const getCascadesAll = async (preset) => {
        //get fb stats
        setCascadesAllFBData([])

        Promise?.all(cascadesAllAccounts?.map(async (client) => {
            const data = await getFB(client.fb, preset)
            setCascadesAllFBData((prevState) => [...prevState, data])
            setCascadesFBStartEnd({
                startDate: new Date((data[0]?.date_start)?.replace(/-/g, '\/')),
                endDate: new Date((data[0]?.date_stop)?.replace(/-/g, '\/'))
            })
        })).then(() => setCascadesRun(prevState => !prevState))

    }

    //FB CUSTOM DATES
    const [cascadesFBCustomDates, setCascadesFBCustomDates] = useState([{
        startDate: addDays(new Date(), -1),
        endDate: addDays(new Date(), 0),
        key: 'selection'
    }])

    const getCascadesAllCustom = async () => {
        //get fb stats
        setCascadesAllFBData([])

        let startDate = cascadesFBCustomDates[0]?.startDate.toISOString().split('T')[0]
        let endDate = cascadesFBCustomDates[0]?.endDate.toISOString().split('T')[0]

        Promise?.all(cascadesAllAccounts?.map(async (client) => {
            const data = await getFBCustom(client.fb, startDate, endDate)
            setCascadesAllFBData((prevState) => [...prevState, data])
        })).then(() => setCascadesRun(prevState => !prevState))
    }

    const [cascadesDaysButton, setCascadesDaysButton] = useState('')
    const cascadesMounted = useRef(false)

    /* Liine */
    const [cascadesLiineData, setCascadesLiineData] = useState([])

    const getCascadesLiineAll = async () => {
        let start = + cascadesFBStartEnd?.startDate?.getFullYear() + "-" + ("0" + (cascadesFBStartEnd?.startDate?.getMonth() + 1)).slice(-2) + "-" + ("0" + cascadesFBStartEnd?.startDate?.getDate()).slice(-2)
        let end = cascadesFBStartEnd?.endDate?.getFullYear() + "-" + ("0" + (cascadesFBStartEnd?.endDate?.getMonth() + 1)).slice(-2) + "-" + ("0" + cascadesFBStartEnd?.endDate?.getDate()).slice(-2)
        let list = []

        //this is running it 13 times because location no longer works
        await Promise.all(cascadesAllLocations?.map(async (e) => {
            const data = await getLiine(352, e, start, end)
            list.push(data?.interactions)
        })).then(() => {
            setCascadesLiineData(list)
        })
        //run only 1 time
    }
    /* Liine */

    useEffect(() => {
        if (cascadesMounted.current) {
            //getCascadesLiineAll()
        } else {
            cascadesMounted.current = true
        }
    }, [cascadesRun])
    ///CASCADES LIINE///



    ///TOWNS GHL///
    const townsAllAccounts = [
        { fb: process.env.REACT_APP_TOWNS_FB }
    ]

    const [townsAllFBData, setTownsAllFBData] = useState([])
    const [townsDaysButton, setTownsDaysButton] = useState('')
    const [townsAllGHLData, setTownsAllGHLData] = useState([])
    const [townsRun, setTownsRun] = useState(true)
    const townsMounted = useRef(false)

    const getTownsAll = async (preset) => {
        //get fb stats
        setTownsAllFBData([])

        Promise?.all(townsAllAccounts?.map(async (client) => {
            const data = await getFB(client.fb, preset)
            setTownsAllFBData((prevState) => [...prevState, data])
        })).then(() => setTownsRun(prevState => !prevState))
    }

    //FB CUSTOM DATES
    const [townsFBCustomDates, setTownsFBCustomDates] = useState([{
        startDate: addDays(new Date(), -1),
        endDate: addDays(new Date(), 0),
        key: 'selection'
    }])

    const getTownsCustom = async () => {
        //get fb stats
        setTownsAllFBData([])

        let startDate = townsFBCustomDates[0]?.startDate.toISOString().split('T')[0]
        let endDate = townsFBCustomDates[0]?.endDate.toISOString().split('T')[0]

        Promise?.all(townsAllAccounts?.map(async (client) => {
            const data = await getFBCustom(client.fb, startDate, endDate)
            setTownsAllFBData((prevState) => [...prevState, data])
        })).then(() => setTownsRun(prevState => !prevState))
    }

    //FB CUSTOM DATES
    const getGHLTowns = async (location_id) => {
        const url = 'https://oradigital.herokuapp.com/getghlcontacts'
        await axios.get(url, { params: { location: location_id } })
            .then((response) => {
                //console.log(response.data)
                setTownsAllGHLData(response.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        if (townsMounted.current) {
            //getGHLTowns(process.env.REACT_APP_PLEASANT_GHL_ID)
        } else {
            townsMounted.current = true
        }
    }, [townsRun])
    ///TOWNS///


    ///GASTONIA GHL///
    const gastoniaAllAccounts = [
        { fb: process.env.REACT_APP_GASTONIA_FB }
    ]

    const [gastoniaAllFBData, setGastoniaAllFBData] = useState([])
    const [gastoniaDaysButton, setGastoniaDaysButton] = useState('')
    const [gastoniaAllGHLData, setGastoniaAllGHLData] = useState([])
    const [gastoniaRun, setGastoniaRun] = useState(true)
    const gastoniaMounted = useRef(false)

    const getGastoniaAll = async (preset) => {
        //get fb stats
        setGastoniaAllFBData([])

        Promise?.all(gastoniaAllAccounts?.map(async (client) => {
            const data = await getFB(client.fb, preset)
            setGastoniaAllFBData((prevState) => [...prevState, data])
        })).then(() => setGastoniaRun(prevState => !prevState))
    }

    //FB CUSTOM DATES
    const [gastoniaFBCustomDates, setGastoniaFBCustomDates] = useState([{
        startDate: addDays(new Date(), -1),
        endDate: addDays(new Date(), 0),
        key: 'selection'
    }])

    const getGastoniaCustom = async () => {
        //get fb stats
        setGastoniaAllFBData([])

        let startDate = gastoniaFBCustomDates[0]?.startDate.toISOString().split('T')[0]
        let endDate = gastoniaFBCustomDates[0]?.endDate.toISOString().split('T')[0]

        Promise?.all(gastoniaAllAccounts?.map(async (client) => {
            const data = await getFBCustom(client.fb, startDate, endDate)
            setGastoniaAllFBData((prevState) => [...prevState, data])
        })).then(() => setGastoniaRun(prevState => !prevState))
    }

    //FB CUSTOM DATES
    const getGHLGastonia = async (location_id) => {
        const url = 'https://oradigital.herokuapp.com/getghlcontacts'
        await axios.get(url, { params: { location: location_id } })
            .then((response) => {
                //console.log(response.data)
                setGastoniaAllGHLData(response.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        if (gastoniaMounted.current) {
            //getGHLTowns(process.env.REACT_APP_PLEASANT_GHL_ID)
        } else {
            gastoniaMounted.current = true
        }
    }, [gastoniaRun])
    ///GASTONIA///



    ////////////////////////////////       LAST 7 DAYS       ////////////////////////////////////
    ///last 7 days///
    //triangle
    const [triangleAllFBData7, setTriangleAllFBData7] = useState([])
    const getTriangleAll7 = async (preset) => {
        //get fb stats
        setTriangleAllFBData7([])
        await Promise?.all(triangleAllAccounts?.map(async (client) => {
            const data = await getFB(client.fb, preset)
            setTriangleAllFBData7((prevState) => [...prevState, data])
        }))
    }

    //morgan hill
    const [morganHillAllFBData7, setMorganHillAllFBData7] = useState([])
    const getMorganHillAll7 = async (preset) => {
        setMorganHillAllFBData7([])
        await Promise?.all(morganHillAllAccounts?.map(async (client) => {
            const data = await getFB(client.fb, preset)
            setMorganHillAllFBData7((prevState) => [...prevState, data])
        }))
    }

    //carolina
    const [carolinaAllFBData7, setCarolinaAllFBData7] = useState([])
    const getCarolinaAll7 = async (preset) => {
        setCarolinaAllFBData7([])

        await Promise?.all(carolinaAllAccounts?.map(async (client) => {
            const data = await getFB(client.fb, preset)
            setCarolinaAllFBData7((prevState) => [...prevState, data])
        }))
    }

    //felton
    const [feltonAllFBData7, setFeltonAllFBData7] = useState([])
    const getFeltonAll7 = async (preset) => {
        setFeltonAllFBData7([])

        await Promise?.all(feltonAllAccounts?.map(async (client) => {
            const data = await getFB(client.fb, preset)
            setFeltonAllFBData7((prevState) => [...prevState, data])
        }))
    }

    //cullman
    const [cullmanAllFBData7, setCullmanAllFBData7] = useState([])
    const getCullmanAll7 = async (preset) => {
        //get fb stats
        setCullmanAllFBData7([])
        await Promise?.all(cullmanAllAccounts?.map(async (client) => {
            const data = await getFB(client.fb, preset)
            setCullmanAllFBData7((prevState) => [...prevState, data])
        }))
    }

    //skin
    const [skinAllFBData7, setSkinAllFBData7] = useState([])
    const getSkinAll7 = async (preset) => {
        //get fb stats
        setSkinAllFBData7([])
        await Promise?.all(skinAllAccounts?.map(async (client) => {
            const data = await getFB(client.fb, preset)
            setSkinAllFBData7((prevState) => [...prevState, data])
        }))
    }

    //smile
    const [smileAllFBData7, setSmileAllFBData7] = useState([])
    const getSmileAll7 = async (preset) => {
        //get fb stats
        setSmileAllFBData7([])
        await Promise?.all(smileAllAccounts?.map(async (client) => {
            const data = await getFB(client.fb, preset)
            setSmileAllFBData7((prevState) => [...prevState, data])
        }))
    }

    //riverrun
    const [riverRunAllFBData7, setRiverRunAllFBData7] = useState([])
    const getRiverRunAll7 = async (preset) => {
        //get fb stats
        setRiverRunAllFBData7([])
        await Promise?.all(riverRunAllAccounts?.map(async (client) => {
            const data = await getFB(client.fb, preset)
            setRiverRunAllFBData7((prevState) => [...prevState, data])
        }))
    }


    //pleasant
    const [pleasantAllFBData7, setPleasantAllFBData7] = useState([])
    const getPleasantAll7 = async (preset) => {
        //get fb stats
        setPleasantAllFBData7([])
        await Promise?.all(pleasantAllAccounts?.map(async (client) => {
            const data = await getFB(client.fb, preset)
            setPleasantAllFBData7((prevState) => [...prevState, data])
        }))
    }

    //garrisonville
    const [garrisonvilleAllFBData7, setGarrisonvilleAllFBData7] = useState([])
    const getGarrisonvilleAll7 = async (preset) => {
        //get fb stats
        setGarrisonvilleAllFBData7([])
        await Promise?.all(garrisonvilleAllAccounts?.map(async (client) => {
            const data = await getFB(client.fb, preset)
            setGarrisonvilleAllFBData7((prevState) => [...prevState, data])
        }))
    }

    //cascades
    const [cascadesAllFBData7, setCascadesAllFBData7] = useState([])
    const getCascadesAll7 = async (preset) => {
        //get fb stats
        setCascadesAllFBData7([])
        await Promise?.all(cascadesAllAccounts?.map(async (client) => {
            const data = await getFB(client.fb, preset)
            setCascadesAllFBData7((prevState) => [...prevState, data])
        }))
    }

    //towns
    const [townsAllFBData7, setTownsAllFBData7] = useState([])
    const getTownsAll7 = async (preset) => {
        //get fb stats
        setTownsAllFBData7([])
        await Promise?.all(townsAllAccounts?.map(async (client) => {
            const data = await getFB(client.fb, preset)
            setTownsAllFBData7((prevState) => [...prevState, data])
        }))
    }

    //gastonia
    const [gastoniaAllFBData7, setGastoniaAllFBData7] = useState([])
    const getGastoniaAll7 = async (preset) => {
        //get fb stats
        setGastoniaAllFBData7([])
        await Promise?.all(gastoniaAllAccounts?.map(async (client) => {
            const data = await getFB(client.fb, preset)
            setGastoniaAllFBData7((prevState) => [...prevState, data])
        }))
    }

    return (
        <ContextState.Provider value={{
            activeMenu,
            setActiveMenu,
            screenSize,
            setScreenSize,
            user,
            loginSuccessToast,
            loginFailedToast,
            logoutToast,
            triangleAllFBData,
            getTriangleAll,
            triangleFBStartEnd,
            triangleAllGHLData,
            allLiineDataTriangle,
            triangleDaysButton,
            setTriangleDaysButton,
            setTriangleFBCustomDates,
            triangleFBCustomDates,
            carolinaFBStartEnd,
            getCarolinaAll,
            carolinaAllFBData,
            carolinaRun,
            carolinaDaysButton,
            setCarolinaDaysButton,
            getTriangleAllCustom,
            morganHillAllFBData,
            morganHillAllGHLData,
            getMorganHillAll,
            morganHillDaysButton,
            setMorganHillDaysButton,
            getMorganHillAllCustom,
            setMorganHillFBCustomDates,
            morganHillFBCustomDates,
            getCarolinaLiineAll,
            allLiineData,
            carolinaFBCustomDates,
            setCarolinaFBCustomDates,
            getCarolinaAllCustom,

            triangleAllFBData7,
            morganHillAllFBData7,
            carolinaAllFBData7,

            feltonAllFBData,
            feltonDaysButton,
            setFeltonDaysButton,
            getFeltonAll,
            getFeltonCustom,
            feltonFBCustomDates,
            setFeltonFBCustomDates,
            feltonAllGHLData,
            feltonAllFBData7,

            setCullmanFBCustomDates,
            cullmanFBCustomDates,
            setCullmanDaysButton,
            getCullmanAllCustom,
            cullmanAllFBData,
            cullmanDaysButton,
            getCullmanAll,
            cullmanLiineData,
            cullmanAllFBData7,

            setSkinFBCustomDates,
            skinFBCustomDates,
            getSkinAllCustom,
            setSkinDaysButton,
            skinAllFBData,
            skinDaysButton,
            getSkinAll,
            skinLiineData,
            skinAllFBData7,

            smileAllFBData,
            setSmileDaysButton,
            smileDaysButton,
            getSmileAll,
            smileAllGHLData,
            setSmileFBCustomDates,
            getSmileCustom,
            smileAllFBData7,

            setRiverRunFBCustomDates,
            riverRunFBCustomDates,
            getRiverRunAllCustom,
            setRiverRunDaysButton,
            riverRunAllFBData,
            riverRunDaysButton,
            getRiverRunAll,
            riverRunLiineData,
            riverRunAllFBData7,

            pleasantAllFBData,
            setPleasantDaysButton,
            pleasantDaysButton,
            getPleasantAll,
            pleasantAllGHLData,
            setPleasantFBCustomDates,
            getPleasantCustom,
            pleasantAllFBData7,

            garrisonvilleAllFBData,
            setGarrisonvilleDaysButton,
            garrisonvilleDaysButton,
            getGarrisonvilleAll,
            garrisonvilleAllGHLData,
            setGarrisonvilleFBCustomDates,
            getGarrisonvilleCustom,
            garrisonvilleAllFBData7,

            cascadesAllFBData,
            setCascadesDaysButton,
            cascadesDaysButton,
            getCascadesAll,
            cascadesLiineData,
            setCascadesFBCustomDates,
            getCascadesAllCustom,
            cascadesAllFBData7,

            townsAllFBData,
            setTownsDaysButton,
            townsDaysButton,
            getTownsAll,
            townsAllGHLData,
            setTownsFBCustomDates,
            getTownsCustom,
            townsAllFBData7,

            gastoniaAllFBData,
            setGastoniaDaysButton,
            gastoniaDaysButton,
            getGastoniaAll,
            gastoniaAllGHLData,
            setGastoniaFBCustomDates,
            getGastoniaCustom,
            gastoniaAllFBData7,

        }}>



            {children}
        </ContextState.Provider>
    )
}

export default ContextAPI

export const useContextState = () => useContext(ContextState)
