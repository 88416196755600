import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useContextState } from '../../ContextAPI';
import DatePicker from './DatePicker';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function DatePickerModal({ setDays, getAllCustom, setDates }) {

    //const { setCarolinaDaysButton, getCarolinaAllCustom } = useContextState()

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <div>
            <button className='px-5 py-2' onClick={handleOpen}>Custom Dates</button>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className='border-2'>
                        <DatePicker setDates={setDates} />
                    </div>
                    <div className='flex justify-end'>
                        <button className=' bg-red-500 hover:bg-red-400 rounded-sm text-white px-6 py-1' onClick={() => handleClose()}>
                            Cancel
                        </button>
                        <button className=' bg-blue-500 hover:bg-blue-400 rounded-sm text-white px-6 py-1' onClick={() => {
                            getAllCustom()
                            setDays('custom')
                            handleClose()
                        }}>Get Report</button>
                    </div>

                </Box>
            </Modal>
        </div>
    );
}
