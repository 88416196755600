import React, { useEffect } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useContextState } from '../ContextAPI'
import { BiHome, BiPowerOff } from 'react-icons/bi'
import { AiOutlineBarChart } from 'react-icons/ai'
import ora_logo from '../assets/ora_vertical.png'
import { MdOutlineCancel } from 'react-icons/md';
import { auth } from '../firebase';
import { signOut } from 'firebase/auth';





const Sidebar = () => {
    const { activeMenu, setActiveMenu, screenSize, setScreenSize, user, logoutToast } = useContextState()

    const activeLink = 'flex flex-row items-center h-10 px-3 rounded-lg text-gray-700 bg-white hover:bg-gray-100 hover:text-gray-700'
    const normalLink = 'flex flex-row items-center h-10 px-3 rounded-lg text-gray-300 hover:bg-gray-100 hover:text-gray-700'

    const ItemBox = ({ item, icon, link, badge }) => {
        return (
            <NavLink to={`/${link}`} onClick={() => handleCloseSideBar()} className={({ isActive }) => isActive ? activeLink : normalLink}>
                <span className="flex items-center justify-center text-md text-gray-400">
                    {icon}
                </span>
                <span className="ml-3 text-sm text-gray-400" >{item}</span>
                <span className='md:block hidden items-center ml-3'>{badge}</span>
            </NavLink>

        )
    }

    const navigate = useNavigate()

    const logout = () => {
        signOut(auth)
        logoutToast()
        localStorage.removeItem('user')
        navigate('/login')
    }



    const handleCloseSideBar = () => {
        if (activeMenu && screenSize <= 900) {
            setActiveMenu(false)
        }
    }


    useEffect(() => {
        const handleResize = () => {
            setScreenSize(window.innerWidth)
        }
        window.addEventListener('resize', handleResize)

        handleResize()

        return () => window.removeEventListener('resize', handleResize)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (screenSize <= 900) {
            setActiveMenu(false)
        } else {
            setActiveMenu(true)
        }
    }, [screenSize])



    return (

        <div className="flex flex-col h-screen text-gray-800 md:overflow-hidden overflow-auto md:hover:overflow-auto bg-purple-900 justify-between">
            {activeMenu && (
                <div
                    className="w-72 transform translate-x-0 transition-transform duration-150 ease-in px-2"
                >
                    <div className="flex items-center justify-end pt-2 -mb-5">
                        <button type='button' onClick={() => { setActiveMenu((prevActiveMenu) => !prevActiveMenu) }} className='text-2xl rounded-full p-3 hover:bg-light-gray block lg:hidden'>
                            <MdOutlineCancel color='white' />
                        </button>
                    </div>

                    <div className="px-3 py-2">
                        <ul className="flex flex-col w-full">
                            <li className="flex mb-8 mt-5 justify-center">
                                <img src={ora_logo} alt='ora-logo' className='h-8'></img>
                            </li>
                            {!user &&
                                <li className="my-px">
                                    <ItemBox item='Login' link='login' icon={<BiHome className='text-2xl' />} />
                                </li>
                            }
                            {user?.email === process.env.REACT_APP_CLIENT1 ? (
                                <div>
                                    <li className="my-px">
                                        <ItemBox item='Home' link='home' icon={<BiHome className='text-2xl' />} />
                                    </li>
                                    <li className="my-px">
                                        <ItemBox item='CarolinasDentist' link='carolina' icon={<AiOutlineBarChart className='text-2xl' />} />
                                    </li>
                                    <li className="my-px">
                                        <ItemBox item='Cascades' link='cascades' icon={<AiOutlineBarChart className='text-2xl' />} />
                                    </li>
                                    <li className="my-px">
                                        <ItemBox item='Cullman' link='cullman' icon={<AiOutlineBarChart className='text-2xl' />} />
                                    </li>
                                    <li className="my-px">
                                        <ItemBox item='Felton' link='felton' icon={<AiOutlineBarChart className='text-2xl' />} />
                                    </li>
                                    <li className="my-px">
                                        <ItemBox item='Garrisonville' link='garrisonville' icon={<AiOutlineBarChart className='text-2xl' />} />
                                    </li>
                                    <li className="my-px">
                                        <ItemBox item='Gastonia' link='gastonia' icon={<AiOutlineBarChart className='text-2xl' />} />
                                    </li>
                                    <li className="my-px">
                                        <ItemBox item='Morgan Hill' link='morganhill' icon={<AiOutlineBarChart className='text-2xl' />} />
                                    </li>
                                    <li className="my-px">
                                        <ItemBox item='Pleasant Plains' link='pleasantplains' icon={<AiOutlineBarChart className='text-2xl' />} />
                                    </li>
                                    <li className="my-px">
                                        <ItemBox item='River Run' link='riverrun' icon={<AiOutlineBarChart className='text-2xl' />} />
                                    </li>
                                    <li className="my-px">
                                        <ItemBox item='Skin Essentials' link='skinessentials' icon={<AiOutlineBarChart className='text-2xl' />} />
                                    </li>
                                    <li className="my-px">
                                        <ItemBox item='Smile Studio' link='smilestudio' icon={<AiOutlineBarChart className='text-2xl' />} />
                                    </li>
                                    <li className="my-px">
                                        <ItemBox item='Towns' link='towns' icon={<AiOutlineBarChart className='text-2xl' />} />
                                    </li>
                                    <li className="my-px">
                                        <ItemBox item='Triangle' link='triangle' icon={<AiOutlineBarChart className='text-2xl' />} />
                                    </li>

                                </div>
                            ) : <></>}
                            {user?.email === process.env.REACT_APP_CLIENT2 ? (
                                <div>
                                    <li className="my-px">
                                        <ItemBox item='Triangle' link='triangle' icon={<AiOutlineBarChart className='text-2xl' />} />
                                    </li>
                                    <li className="my-px">
                                        <ItemBox item='Felton' link='felton' icon={<AiOutlineBarChart className='text-2xl' />} />
                                    </li>
                                </div>
                            ) : <></>}
                            {user?.email === process.env.REACT_APP_CLIENT4 ? (
                                <div>
                                    <li className="my-px">
                                        <ItemBox item='CarolinasDentist' link='carolina' icon={<AiOutlineBarChart className='text-2xl' />} />
                                    </li>
                                </div>
                            ) : <></>}
                            {user?.email === process.env.REACT_APP_CLIENT3 ? (
                                <div>
                                    <li className="my-px">
                                        <ItemBox item='Morgan Hill' link='morganhill' icon={<AiOutlineBarChart className='text-2xl' />} />
                                    </li>
                                </div>
                            ) : <></>}

                        </ul>
                    </div>


                </div >


            )}

            <div className='mb-5 mx-4'>
                {user &&
                    <div className={normalLink}>
                        <button className='flex w-full' onClick={() => logout()}>
                            <span className="flex items-center justify-center text-md text-gray-400">
                                <BiPowerOff className='text-2xl' />
                            </span>
                            <span className="ml-3 text-sm" >Logout</span>
                        </button>
                    </div>
                }

                <div className='mt-4'>
                    <p className='text-xs text-white flex-1'>&copy; 2022 Ora Digital Marketing</p>
                    <p className='text-xs text-white flex-1'>
                        <Link to='/privacypolicy'>
                            <span className='hover:cursor-pointer'>Privacy Policy</span> | <span className='hover:cursor-pointer'>Terms and Conditions</span>
                        </Link>
                    </p>
                </div>
            </div>

        </div >


    )
}

export default Sidebar