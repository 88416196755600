import React, { useState } from 'react'
import { SiMicrosoftexcel } from 'react-icons/si'
import { AiOutlineFilePdf } from 'react-icons/ai'
import { HiOutlineCurrencyDollar, HiOutlineCursorClick } from 'react-icons/hi'
import { BsHandThumbsUp, BsEye } from 'react-icons/bs'
import { MdOutlinePeopleAlt } from 'react-icons/md'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas';
import DatePickerModal from './DatePickerModal'

const Template = ({ fb, setDays, daysButton, getAll, leadData, allClients, allFBKeys, leadSource, getAllCustom, setDates }) => {

    const [account, setAccount] = useState('All')
    const [showNote, setShowNote] = useState(false)

    //dynamic classnames for day buttons
    const normalLink = 'flex w-full justify-center items-center text-purple-700 hover:text-white border border-purple-500 hover:bg-purple-700 font-medium rounded-lg text-sm px-5 py-2 text-center mr-2 mb-2'
    const activeLink = 'flex w-full justify-center items-center bg-purple-700 text-white hover:text-white border border-purple-500 hover:bg-purple-700 font-medium rounded-lg text-sm px-5 py-2 text-center mr-2 mb-2'
    const normalLinkLast = 'w-full text-purple-700 hover:text-white border border-purple-500 hover:bg-purple-700 font-medium rounded-lg text-sm px-5 py-2 text-center mb-2'
    const activeLinkLast = 'w-full bg-purple-700 text-white hover:text-white border border-purple-500 hover:bg-purple-700 font-medium rounded-lg text-sm px-5 py-2 text-center mb-2'
    const normalLinkCustom = 'flex w-full justify-center items-center text-purple-700 hover:text-white border border-purple-500 hover:bg-purple-700 font-medium rounded-lg text-sm text-center mr-2 mb-2'
    const activeLinkCustom = 'flex w-full justify-center items-center bg-purple-700 text-white hover:text-white border border-purple-500 hover:bg-purple-700 font-medium rounded-lg text-sm text-center mr-2 mb-2'

    //function to add commas to numbers
    const numberWithCommas = (x) => {
        return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    //set date variables
    var date_start = null
    var date_stop = null

    let overviewData = []
    let fullData = []

    for (var i = 0; i < allFBKeys?.length; i++) {
        let reach = 0
        let impressions = 0
        let clicks = 0
        let spend = 0
        let ctr = 0
        let cpm = 0
        let totalReach = 0
        let totalImpressions = 0
        let totalClicks = 0
        let totalSpend = 0
        let totalCtr = 0
        let totalCpm = 0

        fb?.forEach((client) => {
            client?.forEach((campaign) => {
                reach = 0
                impressions = 0
                clicks = 0
                spend = 0
                ctr = 0
                cpm = 0
                if (campaign.clientID === allFBKeys[i] && campaign.campaign_name.substring(0, 3) === "Ora") {
                    reach += Number(campaign.reach)
                    impressions += Number(campaign.impressions)
                    spend += Number(campaign.spend)
                    clicks += Number(campaign.inline_link_clicks)
                    ctr += Number(campaign.ctr)
                    totalReach += Number(campaign.reach)
                    totalImpressions += Number(campaign.impressions)
                    totalSpend += Number(campaign.spend)
                    totalClicks += Number(campaign.inline_link_clicks)
                    totalCtr += Number(campaign.ctr)


                    if (isNaN((spend / (impressions / 1000)))) {
                        cpm = 0
                        totalCpm = 0
                    } else {
                        cpm = Number((spend / (impressions / 1000)).toFixed(2))
                        totalCpm = Number((spend / (impressions / 1000)).toFixed(2))
                    }

                    //full data for download
                    fullData.push({
                        client: allClients[i],
                        reach: reach,
                        impressions: impressions,
                        spend: spend.toFixed(2),
                        clicks: clicks,
                        ctr: ctr.toFixed(2),
                        cpm: cpm,
                        campaign_id: campaign.campaign_id,
                        campaign_name: campaign.campaign_name,
                    })
                }
                date_start = new Date((campaign?.date_start)?.replace(/-/g, '\/'))
                date_stop = new Date((campaign?.date_stop)?.replace(/-/g, '\/'))
                //set to very end of that date
                date_stop.setHours(23);
                date_stop.setMinutes(59);
                date_stop.setSeconds(59);
            })
        })

        //data for overview
        overviewData.push({
            client: allClients[i],
            reach: totalReach,
            impressions: totalImpressions,
            spend: Number(totalSpend.toFixed(2)),
            clicks: totalClicks,
            ctr: totalCtr.toFixed(2),
            cpm: isNaN(Number((totalSpend / (totalImpressions / 1000)).toFixed(2))) ? 0 : Number((totalSpend / (totalImpressions / 1000)).toFixed(2))
        })
    }


    let totalLead = 0
    let campaignsData = []


    ///LEAD Set up///
    if (leadSource === 'liine') {
        //Liine
        fullData?.forEach((fb_campaign) => {
            //console.log(fb_campaign)
            var count = 0
            var appointment = 0
            //only using this one now
            leadData[0]?.forEach((d) => {
                //helper_cell for leadbasis filter
                if (d.helper_cell === true) {
                    
                    //find ai id to match fb ad id
                    var lead_id = 1
                    if (d.last_touch_landing_url?.search("utm_campaign") === -1 || d.last_touch_landing_url?.search("utm_campaign") === undefined) {
                        //do nothing
                    } else {
                        var start = d?.last_touch_landing_url?.search("utm_campaign")
                        var end = d?.last_touch_landing_url?.search("utm_content")
                        lead_id = d?.last_touch_landing_url?.substring(start + 13, end - 1)
                    }

                    //using fb ad id
                    if (lead_id === fb_campaign.campaign_id) {
                        count += 1
                        console.log(d.name, d.last_touch_marketing_channel, d.last_touch_location, fb_campaign.client)
                    }

                    //using location name to match and liine filters
                    /* let location
                    for (i = 0; i < allLocations?.length; i++) {
                        if (allLocations[i]?.fb === fb_campaign.client) {
                            location = allLocations[i]?.liine
                        }
                    }
                    if (d.last_touch_location === location &&
                        (d.last_touch_marketing_channel === "Facebook Ads - Brand Awareness"
                            || d.last_touch_marketing_channel === "Facebook Ads - Invisalign"
                            || d.last_touch_marketing_channel === "Facebook Ads - Cosmetic Dentistry"
                            || d.last_touch_marketing_channel === "Facebook Ads - Dental Implant Open House"
                        )
                    ) {
                        count += 1
                    } */
                }

            })
            campaignsData.push(Object.assign(fb_campaign, { leads: count }))
        })
    } else if (leadSource === 'ghl2') { //multiple ghl
        //ghl
        fullData?.forEach((fb_campaign) => {
            //console.log(fb_campaign.campaign_id)
            var count = 0
            leadData?.forEach((location) => {
                Object.entries(location).forEach((account) => {
                    //console.log(account[1])
                    account?.[1].forEach((page) => {
                        page?.forEach((contact) => {
                            //console.log(contact)
                            var newDate = new Date(contact?.dateAdded)
                            //convert to EST
                            const options = {
                                timeZone: "America/New_York",
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                                fractionalSecondDigits: 3,
                                hour12: true,
                            };
                            const estString = newDate.toLocaleString("en-US", options)
                            //convert back to date
                            const estDateTime = new Date(estString)
                            //newDate.setHours(0, 0, 0, 0) //set new date to day only
                            if (estDateTime >= date_start && estDateTime <= date_stop) { //if within the search dates
                                //if tag #2 matches campaign id
                                //console.log(contact?.tags)
                                if (contact?.tags[1] === fb_campaign.campaign_id) {
                                    count += 1
                                    //console.log(contact?.contactName, estDateTime)
                                }
                            }
                        })
                    })
                })
            })
            campaignsData.push(Object.assign(fb_campaign, { leads: count }))
        })
    } else if (leadSource === 'ghl') { //single ghl
        fullData?.forEach((fb_campaign) => {
            var count = 0
            leadData?.forEach((page) => {
                page?.forEach((contact) => {
                    //console.log(contact)
                    var newDate = new Date(contact.dateAdded)
                    //convert to PST
                    const options = {
                        timeZone: "America/New_York",
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                        fractionalSecondDigits: 3,
                        hour12: true,
                    };
                    const estString = newDate.toLocaleString("en-US", options)
                    //convert back to date
                    const estDateTime = new Date(estString)
                    //newDate.setHours(0, 0, 0, 0) //set new date to day only
                    if (estDateTime >= date_start && estDateTime <= date_stop) { //if within the search dates
                        //if tag #2 matches campaign id
                        //console.log(fb_campaign.campaign_id)
                        if (contact?.tags[1] === fb_campaign.campaign_id) {
                            count += 1
                        }
                    }
                })
            })
            campaignsData.push(Object.assign(fb_campaign, { leads: count }))
        })
    }
    ///LEAD Set up///


    let newOverviewData = []
    let singleLead = 0

    overviewData.forEach((client) => {
        let leads = 0
        campaignsData.forEach((campaign) => {
            if (client.client === campaign.client) {
                leads += campaign.leads
                totalLead += campaign.leads
            }
        })
        if (client.client === account) {
            singleLead += leads
        }
        newOverviewData.push(Object.assign(client, { leads: leads }))
    })

    const normalReport = date_start?.toLocaleDateString() + "_to_" + date_stop?.toLocaleDateString() + ".csv"
    const fullReport = "FullReport" + date_start?.toLocaleDateString() + "_to_" + date_stop?.toLocaleDateString() + ".csv"

    const downloadToCSV = (data) => {
        const blob = new Blob([data], { type: 'text/csv' })
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.setAttribute('hidden', '')
        a.setAttribute('href', url)
        a.setAttribute('download', normalReport)
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    }

    const downloadToCSVFullReport = (data) => {
        const blob = new Blob([data], { type: 'text/csv' })
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.setAttribute('hidden', '')
        a.setAttribute('href', url)
        a.setAttribute('download', fullReport)
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    }

    const objectToCsv = (data) => {
        const csvRows = []

        //get headers
        const headers = Object?.keys(data?.[0])
        csvRows?.push(headers.join(','))

        //loop rows
        for (const row of data) {
            const values = headers?.map((head) => {
                const escaped = ('' + row[head])?.replace(/"/g, '\\"')
                //deal with empty/undefined values
                if (escaped === "undefined") {
                    return null
                } else {
                    return `"${escaped}"`
                }
            })
            csvRows.push(values.join(','))
        }
        return csvRows?.join('\n')
        //form escaped comma separated value
    }


    const exportToPDF = () => {
        const fullReport = "Report" + date_start?.toLocaleDateString() + "_to_" + date_stop?.toLocaleDateString() + ".pdf"
        const input = document.getElementById('report');

        html2canvas(input, { scale: 3 })
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                let orientation = ''
                if (canvas.width > canvas.height) {
                    orientation = 'landscape'
                } else if (canvas.width < canvas.height) {
                    orientation = 'potrait'
                }

                const pdf = new jsPDF({
                    orientation: orientation,
                    unit: 'px',
                    format: [canvas.width + 50, canvas.height + 50]
                });
                pdf.addImage(imgData, 'PNG', 25, 25, canvas.width, canvas.height);
                pdf.save(fullReport);
            });
    }


    let totalSpend = 0
    let totalReach = 0
    let totalClicks = 0
    let totalImpressions = 0
    let csvData = []

    for (var j = 0; j < newOverviewData.length; j++) {

        csvData.push({
            client: newOverviewData[j].client,
            reach: newOverviewData[j].reach,
            impressions: newOverviewData[j].impressions,
            clicks: newOverviewData[j].clicks,
            spend: newOverviewData[j].spend,
            ctr: newOverviewData[j].ctr,
            cpm: newOverviewData[j].cpm,
            leads: newOverviewData[j]?.leads
        })

        if (newOverviewData[j].client === account) {
            //get total stats
            totalReach += newOverviewData[j].reach
            totalSpend += Number(newOverviewData[j].spend)
            totalClicks += newOverviewData[j].clicks
            totalImpressions += newOverviewData[j].impressions
        } else if (account === 'All') {
            totalReach += newOverviewData[j].reach
            totalSpend += Number(newOverviewData[j].spend)
            totalClicks += newOverviewData[j].clicks
            totalImpressions += newOverviewData[j].impressions
        }
    }

    let fullCSVData = []

    for (var k = 0; k < campaignsData.length; k++) {
        if (account === 'All') {
            fullCSVData.push({
                client: campaignsData[k].client,
                campaign_name: campaignsData[k].campaign_name,
                campaign_id: campaignsData[k].campaign_id,
                reach: campaignsData[k].reach,
                impressions: campaignsData[k].impressions,
                clicks: campaignsData[k].clicks,
                spend: campaignsData[k].spend,
                ctr: campaignsData[k].ctr,
                cpm: campaignsData[k].cpm,
                leads: campaignsData[k].leads
            })
        } else if (campaignsData[k].client === account) {
            fullCSVData.push({
                client: campaignsData[k].client,
                campaign_name: campaignsData[k].campaign_name,
                campaign_id: campaignsData[k].campaign_id,
                reach: campaignsData[k].reach,
                impressions: campaignsData[k].impressions,
                clicks: campaignsData[k].clicks,
                spend: campaignsData[k].spend,
                ctr: campaignsData[k].ctr,
                cpm: campaignsData[k].cpm,
                leads: campaignsData[k].leads
            })
        }
    }

    const TableRows = ({ client, campaign, reach, impressions, clicks, spend, cpl, ctr, cpm, leads }) => {
        return (<tr className={`hover:bg-purple-200`}>
            <td className='p-1 border-r border-b border-t text-sm'>{client}</td>
            <td className='p-1 text-center border-r border-b border-t text-sm'>{campaign}</td>
            <td className='p-1 text-center border-r border-b border-t text-sm'>{cpl}</td>
            <td className='p-1 text-center border-r border-b border-t text-sm'>{ctr}</td>
            <td className='p-1 text-center border-r border-b border-t text-sm'>{cpm}</td>
            <td className='p-1 text-center border-r border-b border-t text-sm'>{reach}</td>
            <td className='p-1 text-center border-r border-b border-t text-sm'>{impressions}</td>
            <td className='p-1 text-center border-r border-b border-t text-sm'>{clicks}</td>
            <td className='p-1 text-center border-r border-b border-t text-sm'>{spend}</td>
            <td className='p-1 text-center border-b border-t text-sm'>{leads}</td>
        </tr>)
    }

    const TableRowsTotal = ({ campaign, reach, impressions, clicks, spend, ctr, cpm, leads, color }) => {
        return (<tr className={`hover:bg-purple-200 bg-${color} border-t-2 border-black`}>

            <td colSpan={5} className='p-1 text-right'><b>{campaign}</b></td>
            {/* <td className='p-1 text-center'>{ctr}</td>
            <td className='p-1 text-center'>{cpm}</td> */}
            <td className='p-1 text-center text-sm'><b>{reach}</b></td>
            <td className='p-1 text-center text-sm'><b>{impressions}</b></td>
            <td className='p-1 text-center text-sm'><b>{clicks}</b></td>
            <td className='p-1 text-center text-sm'><b>${spend}</b></td>
            <td className='p-1 text-center text-sm'><b>{leads}</b></td>
        </tr>)
    }




    return (
        <div className='overflow-x-auto '>

            <div className='mb-4'>
                <p className='font-semibold lg:text-left lg:ml-1 text-center'>Select A Date Range</p>
            </div>

            {/* last few days buttons */}
            <div className='lg:flex flex-row lg:justify-start justify-between mb-2'>

                <div className={daysButton === 'custom' ? activeLinkCustom : normalLinkCustom} >
                    <DatePickerModal setDays={setDays} getAllCustom={getAllCustom} setDates={setDates} />
                </div>

                <button className={daysButton === 'last_week_mon_sun' ? activeLink : normalLink} onClick={() => {
                    setDays('last_week_mon_sun')
                    getAll('last_week_mon_sun')
                }}>Last Week Mon-Sun</button>

                <button className={daysButton === 'this_week_mon_today' ? activeLink : normalLink} onClick={() => {
                    setDays('this_week_mon_today')
                    getAll('this_week_mon_today')
                }}>This Week Mon-Today</button>

                <button className={daysButton === 'last_7d' ? activeLink : normalLink} onClick={() => {
                    setDays('last_7d')
                    getAll('last_7d')
                }}>Last 7 Days</button>


                <button className={daysButton === 'last_14d' ? activeLink : normalLink} onClick={() => {
                    setDays('last_14d')
                    getAll('last_14d')
                }}>Last 14 Days</button>

                <button className={daysButton === 'last_month' ? activeLink : normalLink} onClick={() => {
                    setDays('last_month')
                    getAll('last_month')
                }}>Last Month</button>

                <button className={daysButton === 'this_month' ? activeLinkLast : normalLinkLast} onClick={() => {
                    setDays('this_month')
                    getAll('this_month')
                }}>This Month</button>
            </div>
            {/* last few days buttons */}


            <div id='report'>
                <div className='mt-4'>
                    <p className='text-4xl text-center font-bold'>Report</p>
                    {date_start === null ? <></> : <p className='text-center mt-1'>{date_start?.toDateString()} - {date_stop?.toDateString()}</p>}
                </div>

                <div className='flex lg:justify-start justify-center'>
                    <select className='border-2 border-purple-500 rounded h-8 lg:w-auto w-full lg:mb-0 mb-4' onChange={(e) => setAccount(e.target.value)}>
                        <option value={'All'}>All</option>
                        {allClients?.map((client, index) => {
                            return (
                                <option key={index} value={client}>{client}</option>
                            )
                        })}
                    </select>
                </div>

                {/* total stats section */}
                <div className='lg:flex flex-row justify-between lg:my-4'>
                    <div className='border-2 border-purple-700 p-6 rounded-md lg:mb-0 mb-2 w-full mr-0 lg:mr-5'>
                        <div className='flex justify-center items-center text-4xl'><HiOutlineCurrencyDollar /></div>
                        <p className='flex justify-center font-semibold text-center text-sm'>Total Spend: ${numberWithCommas(totalSpend.toFixed(2))} </p>
                    </div>

                    <div className='border-2 border-purple-700 p-6 rounded-md lg:mb-0 mb-2 w-full mr-0 lg:mr-5'>
                        <div className='flex justify-center items-center text-4xl'><BsHandThumbsUp /></div>
                        <p className='flex justify-center font-semibold text-center text-sm'>Total Reach: {numberWithCommas(totalReach)} </p>
                    </div>

                    <div className='border-2 border-purple-700 p-6 rounded-md lg:mb-0 mb-2 w-full mr-0 lg:mr-5'>
                        <div className='flex justify-center items-center text-4xl'><BsEye /></div>
                        <p className='flex justify-center font-semibold text-center text-sm'>Total Impressions: {numberWithCommas(totalImpressions)} </p>
                    </div>

                    <div className='border-2 border-purple-700 p-6 rounded-md lg:mb-0 mb-2 w-full mr-0 lg:mr-5'>
                        <div className='flex justify-center items-center text-4xl'><HiOutlineCursorClick /></div>
                        <p className='flex justify-center font-semibold text-center text-sm'>Total Clicks: {numberWithCommas(totalClicks)} </p>
                    </div>

                    <div className='border-2 border-purple-700 p-6 rounded-md lg:mb-0 mb-2 w-full'>
                        <div className='flex justify-center items-center text-4xl'><MdOutlinePeopleAlt /></div>
                        <p className='flex justify-center font-semibold text-center text-sm'>Total Leads: {account === 'All' ? numberWithCommas(totalLead) : numberWithCommas(singleLead)} </p>
                    </div>
                </div>
                {/* total stats section */}


                <div className='overflow-x-auto relative'>
                    <table className='w-full sm:shadow-sm mb-1 border my-6 overflow-auto' id='table'>
                        <thead className='text-white bg-purple-700'>
                            <tr className='mb-2 sm:mb-0'>
                                <th className='p-1 py-4 text-center font-thin'>Client</th>
                                <th className='p-1 py-4 text-center font-thin'>Campaign Name</th>
                                <th className='p-1 py-4 text-center font-thin'>CPL</th>
                                <th className='p-1 py-4 text-center font-thin'>CTR</th>
                                <th className='p-1 py-4 text-center font-thin'>CPM</th>
                                <th className='p-1 py-4 text-center font-thin'>Reach</th>
                                <th className='p-1 py-4 text-center font-thin'>Impressions</th>
                                <th className='p-1 py-4 text-center font-thin'>Clicks</th>
                                <th className='p-1 py-4 text-center font-thin'>Spend</th>
                                <th className='p-1 py-4 text-center font-thin'>Leads</th>
                            </tr>
                        </thead>
                        <tbody>

                            {allClients?.map((client) => {
                                let reach = 0
                                let impressions = 0
                                let clicks = 0
                                let spend = 0
                                let leads = 0
                                let campaignName = ''
                                if (client === account) {
                                    return (

                                        <>
                                            {campaignsData.map((campaign) => {
                                                if (client === campaign.client) { //&& campaign.campaign_name.substring(0, 3) === "Ora") {
                                                    reach += campaign.reach
                                                    impressions += campaign.impressions
                                                    clicks += campaign.clicks
                                                    spend += Number(campaign.spend)
                                                    campaignName = campaign.campaign_name
                                                    leads += campaign.leads
                                                    return (
                                                        <>
                                                            <TableRows
                                                                client={campaign.client}
                                                                campaign={campaign.campaign_name}
                                                                reach={numberWithCommas(campaign.reach)}
                                                                impressions={numberWithCommas(campaign.impressions)}
                                                                clicks={numberWithCommas(campaign.clicks)}
                                                                spend={"$" + numberWithCommas(campaign.spend)}
                                                                cpl={campaign.leads === 0 ? "$0" : "$" + numberWithCommas((campaign.spend / campaign.leads).toFixed(2))}
                                                                ctr={numberWithCommas(campaign.ctr) + "%"}
                                                                cpm={"$" + numberWithCommas(campaign.cpm)}
                                                                leads={numberWithCommas(campaign.leads)} />
                                                        </>
                                                    )
                                                }
                                            })}

                                            {campaignName === ''
                                                ? <TableRows client={client} campaign={"-"} reach={"-"} impressions={"-"} clicks={"-"} spend={"-"} ctr={"-"} cpm={"-"} leads={"-"} />
                                                : <TableRowsTotal client={''} campaign={client + ": "} reach={numberWithCommas(reach)} impressions={numberWithCommas(impressions)} clicks={numberWithCommas(clicks)} spend={numberWithCommas(spend.toFixed(2))} leads={numberWithCommas(leads)} />
                                            }
                                        </>

                                    )
                                } else if (account === 'All') { //display all 
                                    return (

                                        <>
                                            {campaignsData.map((campaign) => {

                                                if (client === campaign.client) { //&& campaign.campaign_name.substring(0, 3) === "Ora") {
                                                    reach += campaign.reach
                                                    impressions += campaign.impressions
                                                    clicks += campaign.clicks
                                                    spend += Number(campaign.spend)
                                                    campaignName = campaign.campaign_name
                                                    leads += campaign.leads
                                                    return (
                                                        <>
                                                            <TableRows
                                                                client={campaign.client}
                                                                campaign={campaign.campaign_name}
                                                                reach={numberWithCommas(campaign.reach)}
                                                                impressions={numberWithCommas(campaign.impressions)}
                                                                clicks={numberWithCommas(campaign.clicks)}
                                                                spend={"$" + numberWithCommas(campaign.spend)}
                                                                cpl={campaign.leads === 0 ? "$0" : "$" + numberWithCommas((campaign.spend / campaign.leads).toFixed(2))}
                                                                ctr={numberWithCommas(campaign.ctr) + "%"}
                                                                cpm={"$" + numberWithCommas(campaign.cpm)}
                                                                leads={numberWithCommas(campaign.leads)} />
                                                        </>
                                                    )
                                                }
                                            })}

                                            {campaignName === ''
                                                ? <TableRows client={client} campaign={"-"} reach={"-"} impressions={"-"} clicks={"-"} spend={"-"} ctr={"-"} cpm={"-"} leads={"-"} />
                                                : <TableRowsTotal client={''} campaign={client + ": "} reach={numberWithCommas(reach)} impressions={numberWithCommas(impressions)} clicks={numberWithCommas(clicks)} spend={numberWithCommas(spend.toFixed(2))} leads={numberWithCommas(leads)} />
                                            }
                                        </>

                                    )
                                }

                            })}
                        </tbody>
                    </table>
                </div>


                {/* notes */}
                {showNote &&
                    <div>
                        <p className='font-bold mt-6 mb-2'>Notes:</p>
                        <form>
                            <textarea className='w-full border-2 h-24' spellCheck='true'>

                            </textarea>
                        </form>
                    </div>
                }


            </div>



            {/* <p className='text-xs'>*From: {date_start?.toDateString()} To: {date_stop?.toDateString()}</p> */}


            {/*  download buttons */}
            <div className='flex-row md:flex justify-end mt-4'>
                <button className='items-center bg-slate-500 text-white py-2 px-4 rounded lg:w-auto w-full mr-4' onClick={() => {
                    if (showNote === true) {
                        setShowNote(false)
                    } else {
                        setShowNote(true)
                    }
                }}>
                    <span className='flex items-center justify-center'>
                        <p className=''>{showNote === false ? 'Add Notes' : 'Remove Notes'}</p>
                    </span>
                </button>
                <button className='items-center bg-cyan-700 text-white py-2 px-4 rounded lg:w-auto w-full mr-4' onClick={() => {
                    downloadToCSVFullReport(objectToCsv(fullCSVData))
                }}>
                    <span className='flex items-center justify-center'>
                        <p className='mr-2'>Download CSV</p>
                        <SiMicrosoftexcel />
                    </span>
                </button>
                <button className='items-center bg-rose-500 text-white py-2 px-4 rounded lg:w-auto w-full' onClick={() => {
                    exportToPDF()
                }}>
                    <span className='flex items-center justify-center'>
                        <p className='mr-2'>Download PDF</p>
                        <AiOutlineFilePdf className='text-lg' />
                    </span>
                </button>
            </div>
            {/*  download buttons */}
        </div>
    )
}

export default Template