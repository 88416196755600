import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Login from "./components/Login";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import { useContextState } from "./ContextAPI";
import 'react-toastify/dist/ReactToastify.css';
import Triangle from "./pages/Triangle";
import Carolinas from "./pages/Carolinas";
import TriangleRoute from "./routes/TriangleRoute";
import MorganHill from "./pages/MorganHill";
import Home from "./components/Home";
import CarolinaRoute from "./routes/CarolinaRoute";
import OraDigitalRoute from "./routes/OraDigitalRoute";
import MorganHillRoute from "./routes/MorganHillRoute";
import Policy from "./pages/Policy";
import Felton from "./pages/Felton";
import Cullman from "./pages/Cullman";
import Skin from "./pages/Skin";
import TemplatePage from "./pages/TemplatePage";
import Error from "./pages/Error";
import Smile from "./pages/Smile";
import RiverRun from "./pages/RiverRun";
import PleasantPlains from "./pages/PleasantPlains";
import Garrisonville from "./pages/Garrisonville";
import Cascades from "./pages/Cascades";
import Towns from "./pages/Towns";
import Gastonia from "./pages/Gastonia";



function App() {

  const { activeMenu, user } = useContextState()

  const loggedUser = localStorage?.getItem('user')
  //const loggedUser = user?.email

  //console.log(loggedUser)

  const HomePageLink = () => {
    if (loggedUser === process.env.REACT_APP_CLIENT1) {
      return <Route path='/' element={<Navigate to="/home" exact />} />
    } else if (loggedUser === process.env.REACT_APP_CLIENT2) {
      return <Route path='/' element={<Navigate to="/triangle" exact />} />
    } else if (loggedUser === process.env.REACT_APP_CLIENT4) {
      return <Route path='/' element={<Navigate to="/carolina" exact />} />
    } else if (loggedUser === process.env.REACT_APP_CLIENT3) {
      return <Route path='/' element={<Navigate to="/morganhill" exact />} />
    } else {
      return <Route path='/' element={<Navigate to="/login" exact />} />
    }
  }



  return (
    <BrowserRouter>
      <div className="flex">
        {activeMenu ? (
          <div className='w-72 fixed z-50'>
            <Sidebar />
          </div>
        ) : (
          <div className='w-0 h-0'>
            <Sidebar />
          </div>
        )}
      </div>
      <div className={`md:static z-10000 w-full-72 min-h-screen ${activeMenu ? 'md:ml-72' : 'flex-2'}`}>
        <Navbar />
        <div>
          <Routes>

            <Route path='/login' element={<Login />} exact />
            {/* {user?.email === process.env.REACT_APP_CLIENT1 ? <Route path='/' element={<Navigate to="/home" exact />} /> : <Route path='/' exact element={<Login />} />} */}
            {HomePageLink()}

            {(user) && <Route element={<TriangleRoute />}>
              <Route exact path='/triangle' element={<Triangle />} />
              <Route exact path='/felton' element={<Felton />} />
            </Route>}

            {(user) && <Route element={<CarolinaRoute />}>
              <Route exact path='/carolina' element={<Carolinas />} />
            </Route>}

            {(user) && <Route element={<MorganHillRoute />}>
              <Route exact path='/morganhill' element={<MorganHill />} />
            </Route>}


            {(user) && <Route element={<OraDigitalRoute />}>
              <Route exact path='/home' element={<Home />} />
              <Route exact path='/triangle' element={<Triangle />} />
              <Route exact path='/carolina' element={<Carolinas />} />
              <Route exact path='/morganhill' element={<MorganHill />} />
              <Route exact path='/felton' element={<Felton />} />
              <Route exact path='/cullman' element={<Cullman />} />
              <Route exact path='/skinessentials' element={<Skin />} />
              <Route exact path='/smilestudio' element={<Smile />} />
              <Route exact path='/riverrun' element={<RiverRun />} />
              <Route exact path='/pleasantplains' element={<PleasantPlains />} />
              <Route exact path='/garrisonville' element={<Garrisonville />} />
              <Route exact path='/cascades' element={<Cascades />} />
              <Route exact path='/towns' element={<Towns />} />
              <Route exact path='/gastonia' element={<Gastonia />} />
              <Route exact path='/template' element={<TemplatePage />} />
            </Route>}

            <Route exact path='/privacypolicy' element={<Policy />} />
            <Route path='*' element={<Error />} exact />

          </Routes>

          <ToastContainer
            autoClose={5000}
            pauseOnFocusLoss={false}
            position="top-center"
          />

        </div>
      </div>


    </BrowserRouter>
  );
}

export default App;
