import React from 'react'
import { useContextState } from '../ContextAPI'
import Template from '../componentsclient/template/Template';

const Triangle = () => {

  const contextValues = useContextState();

  const accountName = 'Triangle Family Dentistry'

  // Create an array to store the values for context
  const valuesArray = [
    contextValues.triangleAllFBData,
    contextValues.setTriangleDaysButton,
    contextValues.triangleDaysButton,
    contextValues.getTriangleAll,
    contextValues.allLiineDataTriangle,
    contextValues.setTriangleFBCustomDates,
    contextValues.getTriangleAllCustom
  ];

  const fb = valuesArray[0]
  const setDays = valuesArray[1]
  const daysButton = valuesArray[2]
  const getAll = valuesArray[3]
  const leadData = valuesArray[4]
  const setDates = valuesArray[5]
  const getAllCustom = valuesArray[6]


  //lead source is liine ghl or ghl2 (multi)
  const leadSource = 'liine'

  const allClients = [
    "TFD - Brier Creek",
    "TFD - Fuquay-Varina",
    "TFD - Green Level",
    "TFD - Kelly Road",
    "TFD - Morrisville",
    "TFD - Wake Forest"
  ]

  //set all clients and fb keys
  const allFBKeys = [
    process.env.REACT_APP_TRIANGLE_FB1,
    process.env.REACT_APP_TRIANGLE_FB2,
    process.env.REACT_APP_TRIANGLE_FB3,
    process.env.REACT_APP_TRIANGLE_FB4,
    process.env.REACT_APP_TRIANGLE_FB5,
    process.env.REACT_APP_TRIANGLE_FB6,
  ]


  return (
    <div className="flex-row text-gray-600 body flex-wrap justify-center w-full px-10">
      <div className='mb-5'>
        <p className='flex items-center lg:justify-start justify-center text-center lg:text-5xl text-3xl tracking-wider font-bold text-white py-10 px-6 bg-gradient-to-r from-purple-900 to-purple-400 rounded-xl'>
          {accountName}
        </p>
      </div>

      <div>
        <Template
          fb={fb}
          setDays={setDays}
          daysButton={daysButton}
          getAll={getAll}
          leadData={leadData}
          allClients={allClients}
          allFBKeys={allFBKeys}
          leadSource={leadSource}
          getAllCustom={getAllCustom}
          setDates={setDates}
        />
      </div>

      <div className='mb-10'></div>

    </div >
  )
}

export default Triangle