import React from 'react'

const Policy = () => {
    return (
        <div className='flex-row text-gray-600 body flex-wrap justify-center w-full px-16 text-sm min-h-screen '>
            <p className='text-2xl font-bold'>
                Privacy Policy
            </p>
            <br />
            <p>
                Our website ("us", "we", or "our") is committed to protecting the privacy of our users. This Privacy Policy explains how we collect, use, and disclose information from users who use our website, which brings in data from Facebook Ads API. By using our website, you agree to the terms of this Privacy Policy.
            </p>
            <br />
            <p className='font-bold'>
                Data Collection and Use
            </p>
            <p>
                Our website brings in data from Facebook Ads API to provide users with insights and analytics on their advertising campaigns. This includes data such as ad performance, reach, impressions, clicks, and more. We display this data only for the purpose of providing these analytics to our users and improving our services.
            </p>
            <br />
            <p>
                We do not collect or store any personally identifiable information (PII) from our users, such as names, addresses, email addresses, or phone numbers. We only display information related to the performance of Facebook Ads, and this information is used solely for the purpose of providing our analytics services.
            </p>
            <br />
            <p className='font-bold'>
                Data Disclosure
            </p>
            <p>
                We do not sell, rent, or share any user data with third parties, except as required by law or to provide our services. We may disclose user data to third-party service providers who assist us in providing our services, such as hosting providers, data processors, and analytics providers. These third-party service providers are contractually obligated to maintain the confidentiality and security of user data and are not permitted to use it for any other purpose.
            </p>
            <br />
            <p className='font-bold'>
                Data Security
            </p>
            <p>
                We take the security of user data seriously and take reasonable measures to protect it from unauthorized access, disclosure, or use. We use industry-standard security technologies and procedures to safeguard user data, including encryption, firewalls, and secure servers. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee the absolute security of user data.
            </p>
            <br />
            <p className='font-bold'>
                Data Retention
            </p>
            <p>
                We retain user data only for as long as necessary to provide our services and as required by law. We may retain aggregated or de-identified data for analytical or statistical purposes, which cannot be used to identify individual users.
            </p>
            <br />
            <p>
                Changes to this Policy
                We may update this Privacy Policy from time to time to reflect changes in our data practices or legal requirements. We will notify users of any material changes to this policy by posting the updated policy on our website or by email.
            </p>
            <br />
        </div >
    )
}

export default Policy