import React from 'react'
//import CarolinaOverview2 from '../componentsclient/carolinas/CarolinaOverview2'
import { useContextState } from '../ContextAPI'
import Template from '../componentsclient/template/Template'



const Carolinas = () => {

  const contextValues = useContextState()

  const accountName = 'CarolinasDentist'

  // Create an array to store the values for context
  const valuesArray = [
    contextValues.carolinaAllFBData,
    contextValues.setCarolinaDaysButton,
    contextValues.carolinaDaysButton,
    contextValues.getCarolinaAll,
    contextValues.allLiineData,
    contextValues.setCarolinaFBCustomDates,
    contextValues.getCarolinaAllCustom
  ];

  const fb = valuesArray[0]
  const setDays = valuesArray[1]
  const daysButton = valuesArray[2]
  const getAll = valuesArray[3]
  const leadData = valuesArray[4]
  const setDates = valuesArray[5]
  const getAllCustom = valuesArray[6]

  //lead source is liine ghl or ghl multi
  const leadSource = 'liine'

  //set all clients and fb keys
  const allClients = [
    "Asheville",
    //"CarolinaDentist.com", //this is renamed
    "Cary",
    "Chapel Hill",
    "Fayetteville",
    "Fuquay-Varina",
    "Garner",
    "Midtown",
    "Porters Neck",
    "Raeford",
    "Southern Pines",
    "Spring Lake",
    "Spring Lake Oral Surgery",
    "Wilmington",
    "Winston-Salem"
  ]

  //set all clients and fb keys
  const allFBKeys = [
    process.env.REACT_APP_CAROLINA_FB0,
    //process.env.REACT_APP_CAROLINA_FB1,
    process.env.REACT_APP_CAROLINA_FB2,
    process.env.REACT_APP_CAROLINA_FB3,
    process.env.REACT_APP_CAROLINA_FB4,
    process.env.REACT_APP_CAROLINA_FB5,
    process.env.REACT_APP_CAROLINA_FB6,
    process.env.REACT_APP_CAROLINA_FB7,
    process.env.REACT_APP_CAROLINA_FB8,
    process.env.REACT_APP_CAROLINA_FB9,
    process.env.REACT_APP_CAROLINA_FB10,
    process.env.REACT_APP_CAROLINA_FB11,
    process.env.REACT_APP_CAROLINA_FB12,
    process.env.REACT_APP_CAROLINA_FB13,
    process.env.REACT_APP_CAROLINA_FB14
  ]


  //liine location names
/*   const allLocations = [
    { fb: "Asheville", liine: "Asheville, NC" },
    { fb: "Cary", liine: "Cary, NC" },
    { fb: "Chapel Hill", liine: "Chapel Hill, NC" },
    { fb: "Fayetteville", liine: "Fayetteville, NC" },
    { fb: "Fuquay-Varina", liine: "Fuquay-Varina, NC" },
    { fb: "Garner", liine: "Garner, NC" },
    { fb: "Midtown", liine: "Midtown" },
    { fb: "Porters Neck", liine: "Porters Neck, NC" },
    { fb: "Raeford", liine: "Raeford, NC" },
    { fb: "Southern Pines", liine: "Southern Pines, NC" },
    { fb: "Spring Lake", liine: "Spring Lake, NC" },
    { fb: "Spring Lake Oral Surgery", liine: "Spring Lake Oral Surgery" },
    { fb: "Wilmington", liine: "The Pointe" },
    { fb: "Winston-Salem", liine: "Winston-Salem, NC" },
  ] */


  return (
    <div className="flex-row text-gray-600 body flex-wrap justify-center w-full px-10">
      <div className='mb-5'>
        <p className='flex items-center lg:justify-start justify-center text-center lg:text-5xl text-3xl tracking-wider font-bold text-white py-10 px-6 bg-gradient-to-r from-purple-900 to-purple-400 rounded-md'>
          {accountName}
        </p>
      </div>

      <div className='mb-10'>

        <Template
          fb={fb}
          setDays={setDays}
          daysButton={daysButton}
          getAll={getAll}
          leadData={leadData}
          allClients={allClients}
          allFBKeys={allFBKeys}
          leadSource={leadSource}
          getAllCustom={getAllCustom}
          setDates={setDates}
        />

      </div>

    </div>
  )
}

export default Carolinas