
import React from 'react'
import { useContextState } from '../ContextAPI'
import { Link } from 'react-router-dom'
import { HiOutlineCurrencyDollar, HiOutlineCursorClick } from 'react-icons/hi'
import { BsHandThumbsUp, BsEye } from 'react-icons/bs'



const Home = () => {
    const {
        morganHillAllFBData7,
        carolinaAllFBData7,
        triangleAllFBData7,
        feltonAllFBData7,
        cullmanAllFBData7,
        skinAllFBData7,
        smileAllFBData7,
        riverRunAllFBData7,
        pleasantAllFBData7,
        garrisonvilleAllFBData7,
        cascadesAllFBData7,
        townsAllFBData7,
    } = useContextState()


    //function to add commas to numbers
    const numberWithCommas = (x) => {
        return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    ////Triangle////
    let totalReachTriangle = 0
    let totalImpressionsTriangle = 0
    let totalClicksTriangle = 0
    let totalSpendTriangle = 0
    //let totalLeadTriangle = 0

    //set all clients and fb keys
    const allFBKeys = [
        process.env.REACT_APP_TRIANGLE_FB1,
        process.env.REACT_APP_TRIANGLE_FB2,
        process.env.REACT_APP_TRIANGLE_FB3,
        process.env.REACT_APP_TRIANGLE_FB4,
        process.env.REACT_APP_TRIANGLE_FB5,
        process.env.REACT_APP_TRIANGLE_FB6,
        process.env.REACT_APP_TRIANGLE_FB7
    ]

    for (var i = 0; i < allFBKeys.length; i++) {
        triangleAllFBData7?.forEach((client) => {
            client?.forEach((campaign) => {
                if (campaign.clientID === allFBKeys[i]) {
                    totalReachTriangle += Number(campaign.reach)
                    totalImpressionsTriangle += Number(campaign.impressions)
                    totalSpendTriangle += Number(campaign.spend)
                    totalClicksTriangle += Number(campaign.inline_link_clicks)
                }
            })
        })
    }


    ////Morgan Hill////
    let totalReachMorganHill = 0
    let totalImpressionsMorganHill = 0
    let totalClicksMorganHill = 0
    let totalSpendMorganHill = 0
    //let totalLeadMorganHill = 0

    //set all clients and fb keys
    const allFBKeysMorganHill = [
        process.env.REACT_APP_MORGANHILL_FB
    ]

    for (var i = 0; i < allFBKeysMorganHill.length; i++) {
        morganHillAllFBData7?.forEach((client) => {
            client?.forEach((campaign) => {
                if (campaign.clientID === allFBKeysMorganHill[i]) {
                    totalReachMorganHill += Number(campaign.reach)
                    totalImpressionsMorganHill += Number(campaign.impressions)
                    totalSpendMorganHill += Number(campaign.spend)
                    totalClicksMorganHill += Number(campaign.inline_link_clicks)
                }
            })
        })
    }


    ///Carolina////
    //set all clients and fb keys
    const allFBKeysCarolina = [
        process.env.REACT_APP_CAROLINA_FB1,
        process.env.REACT_APP_CAROLINA_FB2,
        process.env.REACT_APP_CAROLINA_FB3,
        process.env.REACT_APP_CAROLINA_FB4,
        process.env.REACT_APP_CAROLINA_FB5,
        process.env.REACT_APP_CAROLINA_FB6,
        process.env.REACT_APP_CAROLINA_FB7,
        process.env.REACT_APP_CAROLINA_FB8,
        process.env.REACT_APP_CAROLINA_FB9,
        process.env.REACT_APP_CAROLINA_FB10,
        process.env.REACT_APP_CAROLINA_FB11,
        process.env.REACT_APP_CAROLINA_FB12,
        process.env.REACT_APP_CAROLINA_FB13,
    ]

    let fullData = []
    let totalReachCarolina = 0
    let totalImpressionsCarolina = 0
    let totalClicksCarolina = 0
    let totalSpendCarolina = 0
    //let totalLeadCarolina = 0

    for (var i = 0; i < allFBKeysCarolina.length; i++) {
        carolinaAllFBData7?.forEach((client) => {
            client?.forEach((campaign) => {
                if (campaign.clientID === allFBKeysCarolina[i]) {
                    totalReachCarolina += Number(campaign.reach)
                    totalImpressionsCarolina += Number(campaign.impressions)
                    totalSpendCarolina += Number(campaign.spend)
                    totalClicksCarolina += Number(campaign.inline_link_clicks)
                    fullData.push({
                        campaign_id: campaign.campaign_id,
                    })
                }
            })
        })
    }


    ////Felton////
    let totalReachFelton = 0
    let totalImpressionsFelton = 0
    let totalClicksFelton = 0
    let totalSpendFelton = 0

    //set all clients and fb keys
    const allFBKeysFelton = [
        process.env.REACT_APP_FELTON_FB,
    ]

    for (var i = 0; i < allFBKeysFelton.length; i++) {
        feltonAllFBData7?.forEach((client) => {
            client?.forEach((campaign) => {
                if (campaign.clientID === allFBKeysFelton[i]) {
                    totalReachFelton += Number(campaign.reach)
                    totalImpressionsFelton += Number(campaign.impressions)
                    totalSpendFelton += Number(campaign.spend)
                    totalClicksFelton += Number(campaign.inline_link_clicks)
                }
            })
        })
    }
    ////Felton////

    ////Cullman////
    let totalReachCullman = 0
    let totalImpressionsCullman = 0
    let totalClicksCullman = 0
    let totalSpendCullman = 0

    //set all clients and fb keys
    const allFBKeysCullman = [
        process.env.REACT_APP_CULLMAN_FB,
    ]

    for (var i = 0; i < allFBKeysCullman.length; i++) {
        cullmanAllFBData7?.forEach((client) => {
            client?.forEach((campaign) => {
                if (campaign.clientID === allFBKeysCullman[i]) {
                    totalReachCullman += Number(campaign.reach)
                    totalImpressionsCullman += Number(campaign.impressions)
                    totalSpendCullman += Number(campaign.spend)
                    totalClicksCullman += Number(campaign.inline_link_clicks)
                }
            })
        })
    }
    ////Cullman////

    ////Skin////
    let totalReachSkin = 0
    let totalImpressionsSkin = 0
    let totalClicksSkin = 0
    let totalSpendSkin = 0

    //set all clients and fb keys
    const allFBKeysSkin = [
        process.env.REACT_APP_SKIN_FB,
    ]

    for (var i = 0; i < allFBKeysSkin.length; i++) {
        skinAllFBData7?.forEach((client) => {
            client?.forEach((campaign) => {
                if (campaign.clientID === allFBKeysSkin[i]) {
                    totalReachSkin += Number(campaign.reach)
                    totalImpressionsSkin += Number(campaign.impressions)
                    totalSpendSkin += Number(campaign.spend)
                    totalClicksSkin += Number(campaign.inline_link_clicks)
                }
            })
        })
    }
    ////Skin////

    ////Smile////
    let totalReachSmile = 0
    let totalImpressionsSmile = 0
    let totalClicksSmile = 0
    let totalSpendSmile = 0

    //set all clients and fb keys
    const allFBKeysSmile = [
        process.env.REACT_APP_SMILE_FB,
    ]

    for (var i = 0; i < allFBKeysSmile.length; i++) {
        smileAllFBData7?.forEach((client) => {
            client?.forEach((campaign) => {
                if (campaign.clientID === allFBKeysSmile[i]) {
                    totalReachSmile += Number(campaign.reach)
                    totalImpressionsSmile += Number(campaign.impressions)
                    totalSpendSmile += Number(campaign.spend)
                    totalClicksSmile += Number(campaign.inline_link_clicks)
                }
            })
        })
    }
    ////Smile////


    ////River Run////
    let totalReachRiverRun = 0
    let totalImpressionsRiverRun = 0
    let totalClicksRiverRun = 0
    let totalSpendRiverRun = 0

    //set all clients and fb keys
    const allFBKeysRiverRun = [
        process.env.REACT_APP_RIVERRUN,
    ]

    for (var i = 0; i < allFBKeysRiverRun.length; i++) {
        riverRunAllFBData7?.forEach((client) => {
            client?.forEach((campaign) => {
                if (campaign.clientID === allFBKeysRiverRun[i]) {
                    totalReachRiverRun += Number(campaign.reach)
                    totalImpressionsRiverRun += Number(campaign.impressions)
                    totalSpendRiverRun += Number(campaign.spend)
                    totalClicksRiverRun += Number(campaign.inline_link_clicks)
                }
            })
        })
    }
    ////River Run////


    ////Pleasant////
    let totalReachPleasant = 0
    let totalImpressionsPleasant = 0
    let totalClicksPleasant = 0
    let totalSpendPleasant = 0

    //set all clients and fb keys
    const allFBKeysPleasant = [
        process.env.REACT_APP_PLEASANT_FB,
    ]

    for (var i = 0; i < allFBKeysPleasant.length; i++) {
        pleasantAllFBData7?.forEach((client) => {
            client?.forEach((campaign) => {
                if (campaign.clientID === allFBKeysPleasant[i]) {
                    totalReachPleasant += Number(campaign.reach)
                    totalImpressionsPleasant += Number(campaign.impressions)
                    totalSpendPleasant += Number(campaign.spend)
                    totalClicksPleasant += Number(campaign.inline_link_clicks)
                }
            })
        })
    }
    ////Pleasant////


    ////Garrisonville////
    let totalReachGarrisonville = 0
    let totalImpressionsGarrisonville = 0
    let totalClicksGarrisonville = 0
    let totalSpendGarrisonville = 0

    //set all clients and fb keys
    const allFBKeysGarrisonville = [
        process.env.REACT_APP_GARRISONVILLE_FB,
    ]

    for (var i = 0; i < allFBKeysGarrisonville.length; i++) {
        garrisonvilleAllFBData7?.forEach((client) => {
            client?.forEach((campaign) => {
                if (campaign.clientID === allFBKeysGarrisonville[i]) {
                    totalReachGarrisonville += Number(campaign.reach)
                    totalImpressionsGarrisonville += Number(campaign.impressions)
                    totalSpendGarrisonville += Number(campaign.spend)
                    totalClicksGarrisonville += Number(campaign.inline_link_clicks)
                }
            })
        })
    }
    ////Garrisonville////

    ////Cascades////
    let totalReachCascades = 0
    let totalImpressionsCascades = 0
    let totalClicksCascades = 0
    let totalSpendCascades = 0

    //set all clients and fb keys
    const allFBKeysCascades = [
        process.env.REACT_APP_CASCADES_FB,
    ]

    for (var i = 0; i < allFBKeysCascades.length; i++) {
        cascadesAllFBData7?.forEach((client) => {
            client?.forEach((campaign) => {
                if (campaign.clientID === allFBKeysCascades[i]) {
                    totalReachCascades += Number(campaign.reach)
                    totalImpressionsCascades += Number(campaign.impressions)
                    totalSpendCascades += Number(campaign.spend)
                    totalClicksCascades += Number(campaign.inline_link_clicks)
                }
            })
        })
    }
    ////Cascades////

    ////Towns////
    let totalReachTowns = 0
    let totalImpressionsTowns = 0
    let totalClicksTowns = 0
    let totalSpendTowns = 0

    //set all clients and fb keys
    const allFBKeysTowns = [
        process.env.REACT_APP_TOWNS_FB,
    ]

    for (var i = 0; i < allFBKeysTowns.length; i++) {
        townsAllFBData7?.forEach((client) => {
            client?.forEach((campaign) => {
                if (campaign.clientID === allFBKeysTowns[i]) {
                    totalReachTowns += Number(campaign.reach)
                    totalImpressionsTowns += Number(campaign.impressions)
                    totalSpendTowns += Number(campaign.spend)
                    totalClicksTowns += Number(campaign.inline_link_clicks)
                }
            })
        })
    }
    ////Towns////


    const Box = ({ link, client, spend, reach, impression, clicks }) => {
        return (
            <Link to={link} className='flex flex-col items-center justify-center border-2 border-purple-700 p-6 rounded-md lg:mb-0 mb-2 w-full mr-0 lg:mr-0 hover:bg-purple-300 hover:text-white'>
                <div className=''>
                    <p className='flex font-bold text-center text-3xl mb-2 underline'>{client}</p>
                    <div>
                        <div className='flex items-center'>
                            <HiOutlineCurrencyDollar className='text-xl' />
                            <p className='ml-1 text-base font-bold'>${numberWithCommas(spend?.toFixed(2))}</p>
                        </div>
                        <div className='flex items-center'>
                            <BsHandThumbsUp />
                            <p className='ml-2 text-base font-bold'>{numberWithCommas(reach)}</p>
                        </div>
                        <div className='flex items-center'>
                            <BsEye />
                            <p className='ml-2 text-base font-bold'>{numberWithCommas(impression)}</p>
                        </div>
                        <div className='flex items-center'>
                            <HiOutlineCursorClick />
                            <p className='ml-2 text-base font-bold'>{numberWithCommas(clicks)}</p>
                        </div>
                    </div>
                </div>
            </Link>
        )
    }

    return (
        <div className='flex-row text-gray-600 body flex-wrap justify-center w-full px-10'>
            <p className='flex items-center lg:justify-start justify-center text-center lg:text-5xl text-3xl tracking-wider font-bold text-white py-10 px-6 bg-gradient-to-r from-purple-900 to-purple-400 rounded-xl'>
                Ora Digital Marketing Reports
            </p>

            <div className='flex items-center justify-between mt-4'>

                <div className='flex items-center'>
                    <div className='flex items-center md:mr-2 mr-0'>
                        <HiOutlineCurrencyDollar className='text-xl mr-1' />
                        <p className='font-bold'>Spend</p>
                    </div>
                    <div className='flex items-center md:mr-2 mr-0'>
                        <BsHandThumbsUp className='text-xl mr-1' />
                        <p className='font-bold'>Reach</p>
                    </div>
                    <div className='flex items-center md:mr-2 mr-0'>
                        <BsEye className='text-xl mr-1' />
                        <p className='font-bold'>Impressions</p>
                    </div>
                    <div className='flex items-center md:mr-2 mr-0'>
                        <HiOutlineCursorClick className='text-xl mr-1' />
                        <p className='font-bold'>Clicks</p>
                    </div>
                </div>

                <p className='font-bold text-xl md:text-left text-center md:mb-0 mb-2'>Last 7 Days</p>
            </div>



            <div className='lg:flex flex-row justify-between gap-4 lg:my-4'>

                <Box link='/triangle' client='Triangle' spend={totalSpendTriangle} reach={totalReachTriangle} impression={totalImpressionsTriangle} clicks={totalClicksTriangle} />

                <Box link='/morganhill' client='Morgan Hill' spend={totalSpendMorganHill} reach={totalReachMorganHill} impression={totalImpressionsMorganHill} clicks={totalClicksMorganHill} />

                <Box link='/carolina' client='Carolina' spend={totalSpendCarolina} reach={totalReachCarolina} impression={totalImpressionsCarolina} clicks={totalClicksCarolina} />

            </div>

            <div className='lg:flex flex-row justify-between gap-4 lg:my-4'>

                <Box link='/felton' client='Felton' spend={totalSpendFelton} reach={totalReachFelton} impression={totalImpressionsFelton} clicks={totalClicksFelton} />

                <Box link='/cullman' client='Cullman' spend={totalSpendCullman} reach={totalReachCullman} impression={totalImpressionsCullman} clicks={totalClicksCullman} />

                <Box link='/skinessentials' client='Skin Essentials' spend={totalSpendSkin} reach={totalReachSkin} impression={totalImpressionsSkin} clicks={totalClicksSkin} />
            </div>

            <div className='lg:flex flex-row justify-between gap-4 lg:my-4'>

                <Box link='/smilestudio' client='Smile Studio' spend={totalSpendSmile} reach={totalReachSmile} impression={totalImpressionsSmile} clicks={totalClicksSmile} />

                <Box link='/riverrun' client='River Run' spend={totalSpendRiverRun} reach={totalReachRiverRun} impression={totalImpressionsRiverRun} clicks={totalClicksRiverRun} />

                <Box link='/pleasantplains' client='Pleasant Plains' spend={totalSpendPleasant} reach={totalReachPleasant} impression={totalImpressionsPleasant} clicks={totalClicksPleasant} />

            </div>

            <div className='lg:flex flex-row justify-between gap-4 lg:my-4'>

                <Box link='/garrisonville' client='Garrisonville' spend={totalSpendGarrisonville} reach={totalReachGarrisonville} impression={totalImpressionsGarrisonville} clicks={totalClicksGarrisonville} />

                <Box link='/cascades' client='Cascades' spend={totalSpendCascades} reach={totalReachCascades} impression={totalImpressionsCascades} clicks={totalClicksCascades} />

                <Box link='/towns' client='Towns' spend={totalSpendTowns} reach={totalReachTowns} impression={totalImpressionsTowns} clicks={totalClicksTowns} />

            </div>


        </div >
    )
}

export default Home