import React, { useEffect, useState } from 'react'
import { auth } from '../firebase'
import { signInWithEmailAndPassword, signOut } from 'firebase/auth'
import { useContextState } from '../ContextAPI'
import { useNavigate } from 'react-router-dom'
import { AiOutlineArrowLeft } from 'react-icons/ai'


const Login = () => {

    const { user, loginSuccessToast, loginFailedToast, logoutToast } = useContextState()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const navigate = useNavigate()



    const logout = () => {
        signOut(auth)
        logoutToast()
        localStorage.removeItem('user')
        navigate('/login')
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (!email || !password) {
            //loginFillToast()
            return
        }

        //login
        try {
            const result = await signInWithEmailAndPassword(auth, email, password)

            loginSuccessToast()

            if (result?.user.email === process.env.REACT_APP_CLIENT1) {
                navigate('/home')
            }

            if (result?.user.email === process.env.REACT_APP_CLIENT2) {
                navigate('/triangle')
            }

            if (result?.user.email === process.env.REACT_APP_CLIENT3) {
                navigate('/morganhill')
            }

            if (result?.user.email === process.env.REACT_APP_CLIENT4) {
                navigate('/carolina')
            }

        } catch (error) {
            loginFailedToast()
        }
    }




    return (
        <div>
            {(!user)
                ?
                (
                    <div className="bg-white p-5 max-w-md mx-auto shadow-sm border-2 rounded-xl mt-20">
                        <h2 className="text-4xl px-4">Log In</h2>
                        <form className="mt-10 space-y-8" onSubmit={handleSubmit}>
                            <input
                                className="w-full border rounded h-12 px-4 focus:outline-none"
                                placeholder="Email Address "
                                type="email"
                                onChange={(e) => setEmail(e.target.value)}
                            />

                            <div className="flex items-center ">
                                <input
                                    className="w-full border rounded h-12 px-4 focus:outline-none -mr-7"
                                    placeholder="Password"
                                    type="password"
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="17.607"
                                    height="17.076"
                                    viewBox="0 0 17.607 17.076"
                                >
                                    <path
                                        id="eye-off"
                                        d="M12.392,16.769a8.718,8.718,0,0,1-9.935-5.938A8.675,8.675,0,0,1,3.817,8.2m5.1.79a2.611,2.611,0,1,1,3.692,3.692M8.914,8.985,12.6,12.675M8.916,8.986,6.053,6.124m6.554,6.554,2.863,2.863M2.929,3,6.053,6.124m0,0a8.7,8.7,0,0,1,13.011,4.707,8.723,8.723,0,0,1-3.6,4.708m0,0,3.123,3.123"
                                        transform="translate(-1.957 -2.293)"
                                        fill="none"
                                        stroke="#949090"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeidth="1"
                                    />
                                </svg>
                            </div>
                            <div>
                                <div className="flex flex-col md:flex-row md:items-center justify-between gap-24">
                                    <button
                                        className="text-sm bg-purple-700 cursor-pointer font-regular text-white px-4 py-2 rounded uppercase"
                                        type="submit"
                                        value="login now"
                                    >
                                        Login now
                                    </button>
                                    <button type='button'>
                                        <p className="text-gray-400 text-sm underline self-center md:self-auto mt-4 md:mt-0">
                                            Forgot your password?
                                        </p>
                                    </button>
                                </div>
                            </div>
                        </form>

                    </div>
                )
                :
                (

                    <div className='flex-row text-gray-600 body flex-wrap justify-center w-full px-10'>
                        <p className='flex items-center lg:justify-start justify-center lg:text-4xl text-xl lg:text-start text-center tracking-wider font-semibold text-white py-10 px-6 bg-gradient-to-r from-purple-900 to-purple-400 rounded-xl'>Ora Digital Marketing Reports</p>
                        <p className='flex  items-center border-2 border-purple-700 p-6 rounded-xl mt-5 font-bold'><AiOutlineArrowLeft className='mr-2'/>Access reports from the side bar</p>
                        <div className='md:flex flex-row items-end md:mb-2 my-5'>
                            <div className='border-2 border-purple-700 p-6 rounded-xl'>
                                <p className='text-center cursor-pointer' onClick={() => logout()}><b>Log Out</b></p>
                            </div>
                        </div>
                    </div>
                )
            }



        </div >
    )
}

export default Login