import React from 'react'
import { AiOutlineMenu } from 'react-icons/ai';
import { MdOutlineLogout } from 'react-icons/md'
import { useContextState } from '../ContextAPI';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { signOut } from 'firebase/auth';


const NavButton = ({ title, customFunc, icon }) => (

    <button type='button' onClick={customFunc} className='flex justify-center mx-7 relative py-3 px-2 hover:bg-purple-100 rounded-xl'>
        <span className='absolute inline-flex rounded-full h-2 w-2'>
        </span>
        <div className='flex items-center'>
            <p className='md:block hidden mr-1 text-sm'>{title}</p>
            <span>{icon}</span>
        </div>
    </button>

)


const Navbar = () => {
    const { setActiveMenu, user, logoutToast } = useContextState()
    const navigate = useNavigate()

    const logout = () => {
        signOut(auth)
        logoutToast()
        localStorage.removeItem('user')
        navigate('/login')
    }



    return (
        <div className='flex p-2 relative justify-between items-center'>
            <NavButton
                title=''
                customFunc={() => setActiveMenu((prevActiveMenu) => !prevActiveMenu)}
                icon={<AiOutlineMenu className='-ml-1 text-xl' />}
            />

            {user && <div className='flex items-center '>
                <div className='flex items-center'>
                    <p className='md:block hidden text-sm'>Welcome, {user.email}!</p>
                    {/* <a href='https://www.oradigitalmarketing.com/' target='_blank' className='lg:block hidden mr-2 underline text-sm font-semibold text-purple-900 hover:bg-slate-50 rounded-xl'>Ora Digital Marketing</a>
                    <a href='https://www.gohighlevel.com/' target='_blank' className='lg:block hidden mr-2 underline text-sm font-semibold text-purple-600 hover:bg-slate-50 rounded-xl'>Go High Level</a>
                    <a href='https://www.liine.com/' target='_blank' className='lg:block hidden -mr-4 underline text-sm font-semibold text-green-600 hover:bg-slate-50 rounded-xl'>Liine</a>
 */}                </div>
                <NavButton
                    title='Logout'
                    customFunc={() => { logout() }}
                    icon={<MdOutlineLogout className='text-xl' />}
                />

            </div>}



        </div>
    )
}

export default Navbar