import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import { useContextState } from '../ContextAPI'


const MorganHillRoute = () => {
    const { user } = useContextState()

    if (user?.email === process.env.REACT_APP_CLIENT3 || user?.email === process.env.REACT_APP_CLIENT1) {
        return <Outlet />
    } else {
        return <Navigate to="/" />
    }


}

export default MorganHillRoute