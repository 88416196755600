import React, { useState } from 'react'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';


const DatePicker = ({ setDates }) => {

    const [selectedRange, setSelectedRange] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        },
    ])

    //need this to change only the time zone not the actual date and time
    const convertToEST = (date) => {
        const estTimeZone = 'America/New_York'; // Eastern Standard Time (EST) time zone
        const localOffset = date.getTimezoneOffset(); // Local time zone offset in minutes
        const estOffset = localOffset + getTimezoneOffsetInMinutes(estTimeZone);
        const targetDate = new Date(date.getTime() - estOffset * 60 * 1000);
        return targetDate;
    };

    const getTimezoneOffsetInMinutes = (timeZone) => {
        const currentDate = new Date();
        const timeZoneOffset = currentDate.toLocaleString('en-US', { timeZoneName: 'short', timeZone });
        const [_, offsetString] = timeZoneOffset.split(' ');
        const [hours, minutes] = offsetString.split(':');
        const totalOffsetMinutes = parseInt(hours, 10) * 60 + parseInt(minutes, 10);
        return -totalOffsetMinutes
    }

    const handleDateChange = (ranges) => {
        setSelectedRange([ranges.selection]);
        const convertedDates = {
            startDate: convertToEST(ranges.selection.startDate),
            endDate: convertToEST(ranges.selection.endDate),
            key: 'selection',
        };
        setDates([convertedDates]);
    }


    return (
        <DateRange
            editableDateInputs={true}
            onChange={handleDateChange}
            moveRangeOnFirstSelection={false}
            ranges={selectedRange}
        />
    )

}

export default DatePicker